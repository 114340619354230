var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('h2',{staticClass:"display-1 ml-4"},[_vm._v("Media")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary--text",attrs:{"color":"accent"},on:{"click":function($event){return _vm.$router.push('/media/new')}}},[_vm._v("New")])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","hide-details":"","label":"Search by title"},on:{"input":_vm.applyFilter},model:{value:(_vm.titleSearch),callback:function ($$v) {_vm.titleSearch=$$v},expression:"titleSearch"}})],1)],1),_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.count,"loading":_vm.tableLoading,"no-data-text":"There are no items yet","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.title.en",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'./#/media/'+item._id,"title":item.title.en}},[_c('b',[_vm._v(_vm._s(item.title.en))])])]}},{key:"item.cover_url",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"ma-0",attrs:{"href":'./#/media/'+item._id}},[_c('v-img',{staticClass:"black",attrs:{"src":item.cover_url,"contain":"","width":"160","height":"90"}})],1)]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [(item.url)?_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))]):_vm._e()]}},{key:"item.photos",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((_vm.getPhotosOf(item._id)),function(p){return _c('li',{key:item._id  + '_' + p._id},[_c('a',{attrs:{"href":'./#/photos/'+p._id}},[_vm._v(_vm._s(p.title.en))])])}),0)]}},{key:"item.external",fn:function(ref){
var item = ref.item;
return [(item.external)?_c('span',[_vm._v("Yes")]):_c('span',[_vm._v("No")])]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.requestDelete(item._id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }