<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" v-if="updating" small
                   @click="$router.push(`/photos/${editingItem._id}/hotspots` )" :loading="loading">Hotspots
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <!--
                  <v-col cols="12" v-if="editingItem._id">
                    <v-img max-width="300" max-height="300" contain @click="downloadQr()" style="cursor: pointer"
                           :src="'https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fngs.artcentrica.com%2Fphotos%2F'+editingItem._id+'&chs=300x300&choe=UTF-8&chld=L|2'"/>
                  </v-col>-->
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Priority" v-model="editingItem.priority" persistent-hint
                                  type="number" step="1"
                                  hint="A higher value makes appear this photo prior to other photos with a lower value"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Title*" v-model="editingItem.title.en"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-switch outlined dense label="Enable photo position" v-model="enablePosition"></v-switch>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field :disabled="!enablePosition" outlined dense label="Longitude" type="number"
                                  :step="0.00000001"
                                  v-model="longitude"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field :disabled="!enablePosition" outlined dense label="Latitude" type="number"
                                  :step="0.00000001"
                                  v-model="latitude"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Location" v-model="editingItem.location.en"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-autocomplete outlined dense label="Author*" :items="authorsOptions"
                                    v-model="editingItem.author_id" :rules="[rules.required]"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-autocomplete outlined dense label="Continent" :items="continentsOptions"
                                    v-model="editingItem.continent_id"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-autocomplete outlined dense label="Country" :items="countriesOptions"
                                    v-model="editingItem.country_id"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-autocomplete outlined dense label="Categories" multiple :items="ctgOptions"
                                    v-model="editingItem.category_ids" chips deletable-chips
                                    small-chips></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-autocomplete outlined dense label="Bioregion" multiple :items="bioOptions"
                                    v-model="editingItem.biodiversity_ids" chips deletable-chips
                                    small-chips></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-textarea outlined dense label="Bioregion Connection"
                                v-model="editingItem.bioregion_desc.en"></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Bioregion Credits"
                                  v-model="editingItem.bioregion_credits.en"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-select outlined dense label="Section" :items="sectionsOptions"
                              v-model="editingItem.section_id"></v-select>
                  </v-col>

                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Photo number" v-model="editingItem.code"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Spi ID" v-model="editingItem.spi_id"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Year" v-model="editingItem.year.en"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-textarea outlined dense label="Description" v-model="editingItem.desc.en"></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete outlined dense label="Media" multiple :items="mediaOptions"
                                    v-model="editingItem.media_ids" chips deletable-chips
                                    small-chips></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-textarea outlined dense label="Notes" v-model="editingItem.notes"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-switch class="min-content" label="Published" v-model="editingItem.pub"></v-switch>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-4"/>
                <v-row row wrap>
                  <v-col cols="12"><h2 class="subheading">Image</h2></v-col>
                  <v-col cols="6" sm="4" v-if="editingItem.imgf_url">
                    <v-img :src="getImgfThumb(editingItem.imgf_url)" class="black" contain :aspect-ratio="1"/>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-autocomplete outlined dense label="Image" v-model="editingItem.imgf_id" :items="imgfOptions"
                                    clearable></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" v-if="updating" small
                   @click="$router.push(`/photos/${editingItem._id}/hotspots` )" :loading="loading">Hotspots
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import utils from '../utils';
import {mapGetters, mapActions} from 'vuex';
import log from "../log";
import rules from "../utils/rules";
import config from "@/config";

export default {
  data() {
    return {
      loading: false,
      enablePosition: false,
      longitude: 0,
      latitude: 0,
      editingItem: {
        title: {en: ''},
        desc: {en: ''},
        year: {en: ''},
        location: {en: ''},
        bioregion_desc: {en: ''},
        bioregion_credits: {en: ''},
        notes: '',
        code: '',
        spi_id: '',
        section_id: null,
        author_id: null,
        continent_id: null,
        country_id: null,
        media_ids: [],
        biodiversity_ids: [],
        category_ids: [],
        imgf_id: null,
        position: null,
        priority: 0,
        pub: false
      },
      rules: {
        ...rules,
      }
    }
  },
  computed: {
    ...mapGetters(['token', 'authors', 'continents', 'countries', 'categories', 'biodiversities', 'imgfs', 'sections', 'media']),
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New photo' : 'Edit photo';
    },
    sectionsOptions() {
      return this.sections.map((item) => {
        let text = `${item.order}. ${item.name.en}`;
        return {text, value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    authorsOptions() {
      return this.authors.map((item) => {
        return {text: item.full_name || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    continentsOptions() {
      return this.continents.map((item) => {
        return {text: item.name.en || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    countriesOptions() {
      return this.countries.map((item) => {
        return {text: item.name.en || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    bioOptions() {
      return this.biodiversities.map((item) => {
        return {text: item.name.en || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    ctgOptions() {
      return this.categories.map((item) => {
        return {text: item.name.en || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    mediaOptions() {
      return this.media.map((item) => {
        return {text: item.title.en || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    imgfOptions() {
      return this.imgfs.map((item) => {
        return {text: item.title || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert', 'refreshCollection']),
    getImgfThumb(url) {
      return `${url}?cmd=thumb`;
    },
    downloadQr() {
      const url = config.server + "/api/photos/" + this.editingItem._id + "/qr?y=true&t=" + this.token;
      window.open(url, '_blank');
    },
    loadData(doClone) {
      this.loading = true;
      let id = doClone ? this.$route.query.clone : this.$route.params.id;
      this.get({collection: 'photos', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          if (!reply.data.imgfs_id) reply.data.imgfs_id = [];
          if (!reply.data.bioregion_desc) reply.data.bioregion_desc = {en: ''};
          if (!reply.data.bioregion_credits) reply.data.bioregion_credits = {en: ''};
          this.enablePosition = false;
          if (reply.data.position && reply.data.position.coordinates) {
            this.enablePosition = true;
            this.longitude = reply.data.position.coordinates[0];
            this.latitude = reply.data.position.coordinates[1];
          }

          if (doClone) {
            reply.data._id = undefined;
            delete reply.data._id
          }
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.$router.go(-1)
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },

    async save(afterAction) {
      let updating = this.updating;
      if (!this.$refs.form.validate()) return;
      this.editingItem.width = utils.toFloat(this.editingItem.width, 0)
      this.editingItem.height = utils.toFloat(this.editingItem.height, 0)
      this.editingItem.priority = utils.toFloat(this.editingItem.priority, 0)
      if (this.enablePosition) {
        this.editingItem.position = {
          type: 'Point',
          coordinates: [utils.toFloat(this.longitude, 0), utils.toFloat(this.latitude, 0)]
        }
      } else {
        this.editingItem.position = null;
      }

      this.loading = true;
      let saveFn = updating ? this.update : this.insert;
      try {
        let {data} = await saveFn({collection: 'photos', data: this.editingItem})
        this.sendSuccess({message: "Data saved!"});
        this.loading = false;
        switch (afterAction) {
          case 1:
            await this.$router.replace('/photos');
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/photos/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              title: {en: ''},
              desc: {en: ''},
              year: {en: ''},
              location: {en: ''},
              bioregion_desc: {en: ''},
              bioregion_credits: {en: ''},
              notes: '',
              code: '',
              spi_id: '',
              section_id: null,
              author_id: null,
              continent_id: null,
              country_id: null,
              media_ids: [],
              biodiversity_ids: [],
              category_ids: [],
              imgf_id: null,
              position: null,
              priority: 0,
              pub: false,
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/photos/new`);
            }
            break
        }
      } catch (err) {
        this.loading = false;
        log.d("Save photo error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/photos");
          this.logout();
        }
      }

    }
  },
  async mounted() {
    if (this.$route.params.id !== 'new' || this.$route.query.clone) {
      this.loadData(this.$route.params.id === 'new')
    }

    if (this.$route.params.id === 'new' && this.$route.query.imgf) {
      let {data} = await this.get({collection: 'imgfs', id: this.$route.query.imgf})
      this.editingItem.imgfs_id = [this.$route.query.imgf]
      this.editingItem.pub = true
      this.editingItem.title.en = data.title;
    }

    this.refreshCollection({collection: 'authors'});
    this.refreshCollection({collection: 'continents'});
    this.refreshCollection({collection: 'media'});
    this.refreshCollection({collection: 'imgfs'});
    this.refreshCollection({collection: 'sections'});
    this.refreshCollection({collection: 'countries'});
    this.refreshCollection({collection: 'categories'});
    this.refreshCollection({collection: 'biodiversities'});
  }
}
</script>
