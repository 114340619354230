<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Bioregions</h2>
            <v-spacer />
            <v-btn color="accent" class="secondary--text" @click="$router.push('/bioregions/new')">New</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row row wrap>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field filled rounded dense hide-details v-model="nameSearch" label="Search by name" @input="applyFilter"></v-text-field>
                </v-col>
              </v-row>
              <v-row row wrap>

                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                no-data-text="There are no items yet" :footer-props="footerProps">
                    <template v-slot:item.name.en="{ item }">
                      <a :href="'./#/bioregions/'+item._id" ><b>{{ item.name ? item.name.en : '-'}}</b></a>
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'bioregion form', params : { id : item._id }})">
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)" >
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import log from "../log";
export default {
  data () {
    return {
      refreshTimeout : -1,
      nameSearch : null,
      tableLoading : false,
      footerProps : {
        "items-per-page-text" : "no. items per page",
        "items-per-page-options" : [20,50,100],
        "items-per-page" : 20
      },
      selection : {},
      refreshKey : 0,
      count : 0,
      items : [],
      headers:  [
        { text : "Name", sortable: true, align: 'left', value : 'name.en'},
        { text : "Code", sortable: true, align: 'left', value : 'code'},
        { text : "Delete", sortable: false, align: 'center', value : 'remove', width : 50 },
      ],
      pagination: {
        sortBy : [ "name.en" ],
        sortDesc : [ false ]
      },
      query : {}
    }
  },
  watch: {
    pagination: {
      handler () {
        sessionStorage.biodiversityPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','update']),
    applyFilter() {
      this.query = {};
      this.query.name = this.nameSearch ? this.nameSearch : undefined;
      this.pagination.page = 1;
      sessionStorage.biodiversityQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Delete request', message : 'Are you sure to permanently delete this item?', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'biodiversities', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Item deleted!'});
            }).catch((err)=>{
              let message = err.message ? err.message : "there was an error and the request could not be completed";
              this.sendError({ message });
              log.d("there was an error while removing bioregion",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      if(sortBy && sortBy.length) {
        sort = sortBy[0];
        if(sortDesc && sortDesc[0]) {
          sort = encodeURIComponent('-' + sort);
        } else {
          sort = encodeURIComponent('+' + sort);
        }
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page,
        sort
      };

      this.load({ collection : 'biodiversities', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.status === "success") {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"there was an error while loading data"});
        log.d("there was an error while loading records: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.biodiversityQuery) {
      this.query = JSON.parse(sessionStorage.biodiversityQuery);
      this.nameSearch = this.query.name ? this.query.name : null;
    }
    if(sessionStorage.biodiversityPagination) {
      this.pagination = JSON.parse(sessionStorage.biodiversityPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
  }
}
</script>
