<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{title}}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and continue</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add new</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row row wrap>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-text-field outlined dense label="Title*" v-model="editingItem.title" :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field outlined dense label="Url" v-model="editingItem.url" ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field outlined dense label="Url sorgente" v-model="editingItem.source_url" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row row wrap v-if="updating">
                  <v-col cols="12">
                    <h2 class="title">Conversion details:</h2>
                  </v-col>
                  <v-col cols="12">
                    <ul>
                      <li class="body-1"><b>Source file exists:</b> {{editingItem.source_exists}}</li>
                      <li class="body-1"><b>Source file dimension:</b> {{editingItem.source_size}}</li>
                      <li class="body-1"><b>Imgf file exists:</b> {{editingItem.imgf_exists}}</li>
                      <li class="body-1"><b>Imgf file dimension:</b> {{editingItem.imgf_size}}</li>
                      <li class="body-1"><b>Conversion status:</b> <span :class="conversionStatusClass(editingItem.conversion_status)">{{editingItem.conversion_status ? editingItem.conversion_status : '-'}}</span></li>
                      <li class="body-1"><b>Import error:</b> {{editingItem.import_error ? editingItem.import_error : '-'}}</li>
                    </ul>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import utils from '../utils';
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import _ from 'lodash'
import rules from "@/utils/rules";
export default {
  data () {
    return {
      loading : false,
      editingItem : {
        title : '',
        name : '',
        url : '',
        xlserver : '',
        source_url : '',
        source_exists : false,
        source_size : 0,
        imgf_exists : false,
        imgf_size : 0,
        conversion_status : '',
        import_error : '',
      },
      rules: {
        ...rules,
      }
    }
  },
  computed : {
    ...mapGetters([ 'is_admin']),
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New image' : 'Edit image';
    },
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert', 'refreshLanguages','refreshCollections','refreshCopy']),
    conversionStatusClass( status ) {
      switch (status) {
        case 'fail':
          return 'error--text';
        case 'done':
          return 'success--text';
        case 'running':
          return 'warning--text';
        case 'waiting':
          return 'info--text';
      }
      return ''
    },
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection : 'imgfs', id}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },

    async save( afterAction ) {
      if(!this.$refs.form.validate()) return;
      let updating = this.updating;

      if(this.editingItem.url) {
        let urlParts = this.editingItem.url.split("/");
        if (urlParts.length > 1) {
          this.editingItem.name = urlParts.pop()
        } else {
          this.editingItem.name = urlParts[0];
        }
        this.editingItem.xlserver = urlParts.join("/")+"/";
      }

      let saveFn = updating ? this.update : this.insert;
      try {
        let { data } = await saveFn({collection:'imgfs', data : this.editingItem});
        this.sendSuccess({ message : "Data saved!"});
        switch (afterAction) {
          case 1:
            await this.$router.replace('/imgfs')
            break
          case 2:
            this.editingItem = data;
            if(this.$route.params.id === 'new') {
              await this.$router.replace(`/imgfs/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              title : '',
              name : '',
              url : '',
              xlserver : '',
              source_url : '',
              source_exists : false,
              source_size : 0,
              imgf_exists : false,
              imgf_size : 0,
              conversion_status : '',
              import_error : '',
            }
            if(this.$route.params.id !== 'new') {
              await this.$router.replace(`/imgfs/new`);
            }
            break
        }
      } catch (err) {
        log.d("Save imgf error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({ message });
        if(err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    if(this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>
