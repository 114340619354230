<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Photographers</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" @click="$router.push('/authors/new')">New</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-row row wrap>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field filled rounded dense hide-details v-model="nameSearch" label="Search by name"
                                @input="applyFilter"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-select filled rounded dense hide-details v-model="pubSearch" clearable :items="pubOptions"
                            label="Published" @change="applyFilter"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"><h4>Bulk actions</h4></v-col>
                <v-col cols="12" class="mt-0 pt-0">
                  <v-btn color="accent" small class="secondary--text flex-grow-0 flex-shrink-1" @click="selectAll()">
                    Select all
                  </v-btn>
                  <v-btn color="accent" small class="secondary--text flex-grow-0 flex-shrink-1 ml-2"
                         @click="setPub(true)">Publish selected
                  </v-btn>
                  <v-btn color="accent" small class="secondary--text flex-grow-0 flex-shrink-1 ml-2"
                         @click="setPub(false)">Unpublish selected
                  </v-btn>
                </v-col>

              </v-row>
              <v-row row wrap>

                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination"
                                :server-items-length="count" :loading="tableLoading"
                                no-data-text="There are no items yet" :footer-props="footerProps">
                    <template v-slot:item.select="{ item }">
                      <v-checkbox v-model="selection[item._id]" :key="item._id + 'check' + refreshKey"/>
                    </template>
                    <template v-slot:item.full_name="{ item }">
                      <a :href="'./#/authors/'+item._id"><b>{{ item.full_name ? item.full_name : '-' }}</b></a>
                    </template>
                    <template v-slot:item.pub="{ item }">
                      <strong class="success--text" v-if="item.pub">Yes</strong>
                      <strong class="error--text" v-else>No</strong>
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {mapActions} from 'vuex';
import log from "../log";
import _ from "lodash";

export default {
  data() {
    return {
      refreshTimeout: -1,
      nameSearch: null,
      pubSearch: null,
      pubOptions: [
        {text: "Show published only", value: "true"},
        {text: "Show unpublished only", value: "false"}
      ],
      tableLoading: false,
      footerProps: {
        "items-per-page-text": "no. items per page",
        "items-per-page-options": [20, 50, 100],
        "items-per-page": 20
      },
      selection: {},
      refreshKey: 0,
      count: 0,
      items: [],
      headers: [
        {text: "", sortable: false, align: 'left', value: 'select', width: 60},
        {text: "", sortable: true, align: 'left', value: 'initial', width: 50},
        {text: "Full name", sortable: true, align: 'left', value: 'full_name'},
        {text: "Name", sortable: true, align: 'left', value: 'name'},
        {text: "Surname", sortable: true, align: 'left', value: 'surname'},
        {text: "Instagram", sortable: true, align: 'left', value: 'instagram_name'},
        {text: "Published", sortable: true, align: 'left', value: 'pub'},
        {text: "Delete", sortable: false, align: 'center', value: 'remove', width: 50},
      ],
      pagination: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      query: {}
    }
  },
  watch: {
    pagination: {
      handler() {
        sessionStorage.authorsPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'update']),
    selectAll() {
      let pairs = _.toPairs(this.selection);
      if (pairs.length < this.items.length) {
        for (let i = 0; i < this.items.length; i++) {
          this.selection[this.items[i]._id] = true;
        }
      } else {
        this.selection = {}
      }
      this.refreshKey++;
    },
    async setPub(value) {
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        if (!this.selection[item._id] || item.pub === value) continue;
        item.pub = value;
        try {
          await this.update({collection: 'authors', data: item})
          this.selection[item._id] = false
          this.refreshKey++
        } catch (e) {
          log.d(e)
          this.sendError({message: "there was an error and the request could not be completed"})
        }
      }
      this.sendSuccess({message: 'publication status updated'})
      this.refresh()
    },
    applyFilter() {
      this.query = {};
      this.query.name = this.nameSearch ? this.nameSearch : undefined;
      this.query.pub = this.pubSearch ? this.pubSearch : undefined;
      this.pagination.page = 1;
      sessionStorage.authorsQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    requestDelete(id) {
      this.requestConfirm({
        title: 'Delete request', message: 'Are you sure to permanently delete this item?', callback: (accepted) => {
          if (accepted) {
            this.delete({collection: 'authors', id}).then(() => {
              this.refresh();
              this.sendSuccess({message: 'Item deleted!'});
            }).catch((err) => {
              let message = err.message ? err.message : "there was an error and the request could not be completed";
              this.sendError({message});
              log.d("there was an error while removing author", err);
              if (err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }
      });
    },
    refresh() {
      this.tableLoading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.pagination;
      let sort = null;

      if (sortBy && sortBy.length) {
        sort = sortBy[0];
        if (sortDesc && sortDesc[0]) {
          sort = encodeURIComponent('-' + sort)
        } else {
          sort = encodeURIComponent('+' + sort)
        }
      }

      let filter = {
        query: this.query,
        limit: itemsPerPage,
        page,
        sort
      };

      this.load({collection: 'authors', filter}).then((reply) => {
        this.tableLoading = false;
        if (reply.status === "success") {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err) => {
        this.tableLoading = false;
        this.sendError({message: "there was an error while loading data"});
        log.d("there was an error while loading authors: " + err);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if (sessionStorage.authorsQuery) {
      this.query = JSON.parse(sessionStorage.authorsQuery);
      this.nameSearch = this.query.name ? this.query.name : null;
      if (this.query.pub) {
        this.pubSearch = this.query.pub
      }
    }
    if (sessionStorage.authorsPagination) {
      this.pagination = JSON.parse(sessionStorage.authorsPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
  }
}
</script>
