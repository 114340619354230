import axios from 'axios'
import log from '../log';
import config from "../config";

const baseUrl = config.server;

const handleError = (error, reject) => {
    if (error.response) {
        // Request made and server responded
        log.i(error.response.data);
        log.i(error.response.status);
        log.i(error.response.headers);
        const message = error.response.data ? error.response.data.message : error.response.data
        reject({status: error.response.status, message})
    } else if (error.request) {
        // The request was made but no response was received
        log.i(error.request);
        reject({status: 500, message: 'errore di connessione al server'})
    } else {
        // Something happened in setting up the request that triggered an Error
        log.i(error.message)
        reject({status: 500, message: 'errore di connessione al server'});
    }

}
export default {
    login(email, password) {
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/api/login`, {email, password}).then((response) => {
                log.d(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        })

    },
    heartbeat(token) {
        return new Promise((resolve, reject) => {
            axios.get(`${baseUrl}/api/heartbeat`, {headers: {'x-token': `Bearer ${token}`}}).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        })
    },
    getProfile(token) {
        return new Promise((resolve, reject) => {
            axios.get(`${baseUrl}/api/profile`, {headers: {'x-token': `Bearer ${token}`}}).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        })
    },

    saveProfile(token, data) {
        return new Promise((resolve, reject) => {
            axios.put(`${baseUrl}/api/profile`, data, {headers: {'x-token': `Bearer ${token}`}}).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        })
    },
    restLoad(token, collection, query, limit, page, sort) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${collection}?limit=${limit}&page=${page - 1}`;
            for (const k in query) {
                if (query.hasOwnProperty(k) && query[k] !== undefined && query[k] !== null)
                    url += "&" + k + "=" + query[k];
            }
            if (sort) {
                url += "&sort=" + sort;
            }

            const config = token ? {headers: {'x-token': `Bearer ${token}`}} : {};
            axios.get(url, config).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        })
    },
    restGet(token, collection, id) {
        return new Promise((resolve, reject) => {
            let url = id ? `${baseUrl}/api/${collection}/${id}` : `${baseUrl}/api/${collection}`;
            const config = token ? {headers: {'x-token': `Bearer ${token}`}} : {};
            axios.get(url, config).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },

    restPost(token, collection, data) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${collection}`;
            const config = token ? {headers: {'x-token': `Bearer ${token}`}} : {};
            axios.post(url, data, config).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },


    restUpload(token, path, data) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${path}`;
            const config = token ? {
                headers: {'x-token': `Bearer ${token}`, 'Content-Type': 'multipart/form-data'},
            } : {
                headers: {'Content-Type': 'multipart/form-data'}
            };
            axios.post(url, data, config).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },


    restPut(token, collection, data) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${collection}/${data._id}`;
            const config = token ? {headers: {'x-token': `Bearer ${token}`}} : {};
            axios.put(url, data, config).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },
    restDelete(token, collection, id) {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/api/${collection}/${id}`;
            const config = token ? {headers: {'x-token': `Bearer ${token}`}} : {};
            axios.delete(url, config).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },
    rawGet(url) {
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },
    rawApiGet: (token, path) => {
        let url = baseUrl + "/api/" + path;
        return new Promise((resolve, reject) => {
            const config = token ? {headers: {'x-token': `Bearer ${token}`}} : {};
            axios.get(url, config).then((response) => {
                log.i(response);
                resolve(response.data);
            }).catch((err) => {
                handleError(err, reject);
            });
        });
    },
}