<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Images</h2>
            <v-spacer />
            <v-btn color="accent" class="secondary--text" small @click="$router.push('/import/imgfs')">Import</v-btn>
            <v-btn color="accent" class="secondary--text ml-2" small @click="$router.push('/imgfs/new')">New</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row row wrap>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field filled rounded dense hide-details v-model="titleSearch" label="Search by title" @input="applyFilter"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-select filled rounded dense hide-details v-model="statusSearch" label="Conversion status" :items="statusOptions" @change="applyFilter" clearable></v-select>
                </v-col>
              </v-row>
              <v-row row wrap>

                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                no-data-text="There are no items yet" :footer-props="footerProps" >
                    <template v-slot:item.thumb="{ item }">
                      <a :href="'./#/imgfs/'+item._id" ><v-img :src="item.url + '?cmd=thumb'" :alt="item.url" contain width="80" height="80" style="background: #000"/></a>
                    </template>
                    <template v-slot:item.title="{ item }">
                      <a :href="'./#/imgfs/'+item._id" :title="item.url"><b>{{ item.title }}</b></a>
                    </template>
                    <template v-slot:item.photos="{ item }">
                      <a class="d-block" v-for="art in item.photos" :key="item._id+art._id" :href="'./#/photos/'+art._id" :title="art.title"><b>{{ art.title }}</b></a>
                    </template>
                    <template v-slot:item.imgf_size="{ item }" >
                      <span  v-if="item.imgf_exists">{{ (item.imgf_size / 1024 / 1024).toFixed(2)}} MB</span>
                      <span v-else>-</span>
                    </template>
                    <template v-slot:item.source_size="{ item }" >
                      <span v-if="item.source_exists">{{ (item.source_size / 1024 / 1024).toFixed(2)}} MB</span>
                      <span v-else>-</span>
                    </template>
                    <template v-slot:item.conversion_status="{ item }">
                      <span :class="conversionStatusClass(item.conversion_status)">{{ item.conversion_status ? item.conversion_status : '-' }}</span>
                      <v-btn @click="retryConversion(item._id)" v-if="item.conversion_status === 'fail' && !is_translator" text icon color="accent"><v-icon>refresh</v-icon></v-btn>
                    </template>
                    <template v-slot:item.gen="{ item }">
                      <v-btn color="primary" dark text icon v-on:click="$router.push(`/photos/new?imgf=${item._id}`)">
                        <v-icon dark>mdi-auto-fix</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'imgf form', params : { id : item._id }})">
                        <v-icon dark>mdi-edit</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.remove_source="{ item }">
                      <v-btn v-if="item.source !== ''" color="error" dark text icon v-on:click="requestSourceDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from "../utils";
import _ from "lodash";
import log from "@/log";
export default {
  data () {
    return {
      refreshKey : 0,
      refreshTimeout : -1,
      titleSearch : null,
      statusSearch : null,
      tableLoading : false,
      count : 0,
      footerProps : {
        "items-per-page-text" : "no. items per page",
        "items-per-page-options" : [20,50,100],
        "items-per-page" : 20
      },
      items : [],
      pagination: {},
      query : {},
      statusOptions : [
        { text : 'waiting', value : 'waiting' },
        { text : 'running', value : 'running' },
        { text : 'done', value : 'done' },
        { text : 'failed', value : 'fail' },
      ],
      infofs : {},
    }
  },
  watch: {
    pagination: {
      handler () {
        sessionStorage.imgfsPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  computed : {
    ...mapGetters(['is_admin']),
    server() {
      return config.server;
    },
    headers() {
      return [
        { text : '', sortable: false, align: 'center', width : 80, value : 'thumb' },
        { text : 'Title', sortable: true, align: 'left', value : 'title' },
        { text : 'Photos', sortable: false, align: 'left', value : 'photos' },
        { text : 'File dimension', sortable: true, align: 'left', value : 'imgf_size' },
        { text : 'Source dimension', sortable: true, align: 'left', value : 'source_size' },
        { text : 'Conversion status', sortable: true, align: 'left', value : 'conversion_status' },
        { text : 'Gen. photo', sortable: false, align: 'center', value : 'gen', width : 50 },
        { text : 'Del. source', sortable: false, align: 'center', value : 'remove_source', width : 50 },
        { text : 'Delete', sortable: false, align: 'center', value : 'remove', width : 50 },
      ]
    },
  },
  methods : {
    ...mapActions(['get','sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','update']),
    conversionStatusClass( status ) {
      switch (status) {
        case 'fail':
          return 'error--text';
        case 'done':
          return 'success--text';
        case 'running':
          return 'warning--text';
        case 'waiting':
          return 'info--text';
      }
      return ''
    },
    selectAll() {
      let pairs = _.toPairs(this.selection);
      if(pairs.length < this.items.length) {
        for(let i = 0; i < this.items.length; i++) {
          this.selection[this.items[i]._id] = true;
        }
      } else {
        this.selection = {}
      }
      this.refreshKey++;
    },

    applyFilter() {
      this.query = {};
      this.query.title = this.titleSearch ? this.titleSearch : undefined;
      this.query.status = this.statusSearch ? this.statusSearch : undefined;
      this.pagination.page = 1
      sessionStorage.imgfsQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Delete request', message : 'Are you sure to permanently delete this item?', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'imgfs', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Item deleted!'});
            }).catch((err)=>{
              let message = err.message ? err.message : "there was an error and the request could not be completed";
              this.sendError({ message });
              log.d("there was an error while removing image",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }});
    },
    requestSourceDelete( id ) {
      this.requestConfirm({ title :'Delete request', message : 'Are you sure to permanently delete the source file of this image?', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'imgfs', id: id + "/source" }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Source file deleted!'});
            }).catch((err)=>{
              let message = err.message ? err.message : "there was an error and the request could not be completed";
              this.sendError({ message });
              log.d("there was an error while removing image sorgente",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }});
    },
    retryConversion( id ) {
      this.get({collection:'imgfs', id : `${id}/retry`}).then(this.refresh).catch((err)=>{
        if(config.debug) console.log(err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      if(sortBy && sortBy.length) {
        sort = sortBy[0];
        if(sortDesc && sortDesc[0]) {
          sort = encodeURIComponent('-' + sort);
        } else {
          sort = encodeURIComponent('+' + sort);
        }
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page,
        sort
      };

      this.load({ collection : 'imgfs', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.status === "success") {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"there was an error while loading data"});
        log.d("there was an error while loading images: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.imgfsQuery) {
      this.query = JSON.parse(sessionStorage.imgfsQuery);
      if(this.query.title) {
        this.titleSearch = this.query.title
      }
      if(this.query.status) {
        this.statusSearch = this.query.status
      }
    }
    if(sessionStorage.imgfsPagination) {
      this.pagination = JSON.parse(sessionStorage.imgfsPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
  }
}
</script>
