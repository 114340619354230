import Router from 'vue-router'
import goTo from 'vuetify/lib/services/goto'
import Tools from '../pages/Tools'
import Authors from '../pages/Authors'
import AuthorsForm from '../pages/AuthorsForm'

import Photos from '../pages/Photos'
import Continents from '../pages/Continents'
import ContinentForm from "../pages/ContinentForm";

import Imgfs from '../pages/Imgfs'
import ImgfForm from "../pages/ImgfForm";
import PhotoForm from "../pages/PhotoForm";
import ImgfImport from "../pages/ImgfImport";
import Users from "../pages/Users";
import UserForm from "../pages/UserForm";
import Login from "../pages/Login";
import Countries from "@/pages/Countries";
import CountryForm from "@/pages/CountryForm";
import Categories from "@/pages/Categories";
import CategoryForm from "@/pages/CategoryForm";
import Bioregions from "@/pages/Bioregions";
import BioregionForm from "@/pages/BioregionForm";
import Media from "@/pages/Media";
import MediaForm from "@/pages/MediaForm";
import Poi from "@/pages/Poi";
import PoiForm from "@/pages/PoiForm";
import Sections from "@/pages/Sections";
import SectionForm from "@/pages/SectionForm";
import LessonForm from "@/pages/LessonForm";
import Lessons from "@/pages/Lessons";
import Slides from "@/pages/Slides";
import SlideForm from "@/pages/SlideForm";
import HspCategories from "@/pages/HspCategories";
import HspCategoryForm from "@/pages/HspCategoryForm";
import Hotspots from "@/pages/Hotspots";
import Survey from "@/pages/Survey";
import CMS from "@/pages/CMS";
import Settings from "@/pages/Settings";
import SurveyResults from "@/pages/SurveyResults";

const router = new Router({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/users',
            name: 'users',
            component: Users,
        },
        {
            path: '/users/:id',
            name: 'user form',
            component: UserForm,
        },
        {
            path: '/photos',
            name: 'photos',
            component: Photos,
        },
        {
            path: '/photos/:id',
            name: 'photo form',
            component: PhotoForm,
        },
        {
            path: '/photos/:id/hotspots',
            name: 'photo hotspots',
            component: Hotspots,
        },
        {
            path: '/lessons',
            name: 'lessons',
            component: Lessons,
        },
        {
            path: '/lessons/:id',
            name: 'lesson form',
            component: LessonForm,
        },
        {
            path: '/lessons/:id/slides',
            name: 'slides',
            component: Slides,
        },
        {
            path: '/lessons/:lesson_id/slides/:id',
            name: 'slide form',
            component: SlideForm,
        },
        {
            path: '/authors',
            name: 'authors',
            component: Authors,
        },
        {
            path: '/authors/:id',
            name: 'author form',
            component: AuthorsForm,
        },
        {
            path: '/continents',
            name: 'continents',
            component: Continents,
        },
        {
            path: '/continents/:id',
            name: 'continent form',
            component: ContinentForm,
        },
        {
            path: '/countries',
            name: 'countries',
            component: Countries,
        },
        {
            path: '/countries/:id',
            name: 'country form',
            component: CountryForm,
        },
        {
            path: '/categories',
            name: 'categories',
            component: Categories,
        },
        {
            path: '/categories/:id',
            name: 'category form',
            component: CategoryForm,
        },
        {
            path: '/hspcategories',
            name: 'hspcategories',
            component: HspCategories,
        },
        {
            path: '/hspcategories/:id',
            name: 'hspcategory form',
            component: HspCategoryForm,
        },
        {
            path: '/sections',
            name: 'sections',
            component: Sections,
        },
        {
            path: '/sections/:id',
            name: 'section form',
            component: SectionForm,
        },
        {
            path: '/bioregions',
            name: 'bioregions',
            component: Bioregions,
        },
        {
            path: '/bioregions/:id',
            name: 'bioregion form',
            component: BioregionForm,
        },
        {
            path: '/media',
            name: 'media',
            component: Media,
        },
        {
            path: '/media/:id',
            name: 'media form',
            component: MediaForm,
        },
        {
            path: '/poi',
            name: 'poi',
            component: Poi,
        },
        {
            path: '/poi/:id',
            name: 'poi form',
            component: PoiForm,
        },
        {
            path: '/imgfs',
            name: 'imgfs',
            component: Imgfs,
        },
        {
            path: '/imgfs/:id',
            name: 'imgf form',
            component: ImgfForm,
        },
        {
            path: '/import/imgfs',
            name: 'imgf import',
            component: ImgfImport,
        },
        {
            path: '/tools',
            name: 'tools',
            component: Tools,
        },
        {
            path: '/survey',
            name: 'survey',
            component: Survey,
        },
        {
            path: '/survey/results',
            name: 'survey results',
            component: SurveyResults,
        },
        {
            path: '/cms',
            name: 'cms',
            component: CMS,
        }, {
            path: '/settings',
            name: 'settings',
            component: Settings,
        },
        {
            path: '*',
            redirect: {
                name: 'photos',
            },
        },
    ],
    root: '/',
});

router.afterEach((from, to) => {
    goTo(0);
});

export default router;
