<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-text-field type="email" label="Email*" outlined dense v-model="editingItem.email"
                                  :rules="[rules.required, rules.email]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-text-field label="Name*" outlined dense v-model="editingItem.name"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-text-field label="Surname*" outlined dense v-model="editingItem.surname"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="6" xl="4">
                    <v-select label="User role*" :items="roles" outlined dense v-model="editingItem.role"></v-select>
                  </v-col>


                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-text-field :label="'Password' + (updating ? '' : '*')" outlined dense
                                  v-model="editingItem.password" counter
                                  :rules="updating ? [rules.password] : [rules.required, rules.password]"
                                  :type="showPwd ? 'text' : 'password'"
                                  :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="showPwd = !showPwd"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-text-field :label="'Confirm password' + (updating ? '' : '*')" outlined dense
                                  v-model="editingItem.confirm_password" counter
                                  :type="showConfPwd ? 'text' : 'password'"
                                  :append-icon="showConfPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="showConfPwd = !showConfPwd"
                                  :rules="[rules.sameAs(editingItem.password)]"></v-text-field>
                  </v-col>

                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import log from '../log';
import {mapGetters, mapActions} from 'vuex';
import rules from "../utils/rules";
import moment from 'moment'

export default {
  data() {
    return {
      loading: false,
      selectedOrganization: '',
      selectedOrganizationRole: '',
      showPwd: false,
      showConfPwd: false,
      editingItem: {
        email: '',
        name: '',
        surname: '',
        password: null,
        confirm_password: '',
        role: 'user',
      },
      rules: {
        ...rules,
      }

    }
  },
  computed: {
    ...mapGetters(['is_root']),
    updating() {
      return !!this.editingItem._id
    },
    roles() {
      let items = [
        {text: "User", value: 'user'},
        {text: "Editor", value: 'editor'},
        {text: "Administrator", value: 'admin'}
      ];

      if (this.is_root) {
        items.push({text: "Root", value: 'root'});
      }
      return items;
    },
    isEditing() {
      return this.$route.params.id !== 'new'
    },
    title() {
      return this.$route.params.id === 'new' ? 'New user' : 'Edit user';
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert']),
    formatDate(date) {
      if (!date) {
        return "-";
      }
      return moment(date).format("DD/MM/YYYY, HH:mm")
    },
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'users', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.$router.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    save(afterAction) {
      if (!this.$refs.form.validate()) return;
      let updating = this.updating;
      let saveFn = updating ? this.update : this.insert;
      this.loading = true;
      saveFn({collection: 'users', data: this.editingItem}).then((reply) => {
        this.sendSuccess({message: "User saved"});
        this.loading = false;
        switch (afterAction) {
          case 1:
            //go back
            this.$router.replace("/users");
            break;
          case 2:
            //stay
            if (this.$route.params.id === "new") {
              this.$router.replace("/users/" + reply.data._id);
            } else {
              this.editingItem = reply.data;
            }
            break;
          case 3:
            // new
            if (this.$route.params.id === "new") {
              this.editingItem = {
                email: '',
                name: '',
                surname: '',
                password: null,
                confirm_password: '',
                role: 'user',
              };
            } else {
              this.$router.replace("/users/new");
            }
            break;
        }
      }).catch((err) => {
        this.loading = false;
        log.d("Save user error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          this.$router.replace("/");
          this.logout();
        }
      })

    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>
