import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#06090A',
        secondary: '#242627', //#242627 #06090A
        accent: '#FFCC00',
        error: '#FF5252',
        info: '#F4F4F4',
        success: '#4CAF50',
        warning: '#FD8B4B'
      },
    },
  },
    lang: {
      locales: { en },
      current: 'en',
    },
});
