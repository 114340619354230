<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ L('Hotspot categories tree') }}</h2>
            <v-spacer/>
            <v-btn color="accent" text @click="$router.push('/hspcategories/new')">
              {{ L('New') }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-row row wrap>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field filled rounded dense hide-details v-model="nameSearch" :label="L('Search by name')"
                                clearable :hint="L('This filter is active with min 3 chars')"
                                @input="saveFiltersInSession();"></v-text-field>
                </v-col>
              </v-row>
              <v-row row wrap>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-treeview id="categories-tree" :items="items" item-key="_id" item-text="name.en"
                              open-on-click :open="opened" activatable hoverable :active.sync="active">
                    <template slot="prepend" slot-scope="{ item, open, leaf }">
                      <v-btn v-if="leaf" :class="item.highlighted ? 'highlighted ma-0' : 'ma-0'" icon
                             text color="error" @click="requestDelete(item._id, item.name.en)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-icon v-if="leaf" color="primary" :class="item.highlighted ? 'highlighted' : ''">mdi-tag
                      </v-icon>
                      <v-icon v-if="!leaf" color="primary" :class="item.highlighted ? 'highlighted' : ''">mdi-folder
                      </v-icon>
                    </template>
                    <template slot="append" slot-scope="{ item, open, leaf }">
                      <v-btn class="ma-0" icon text color="accent"
                             @click="$router.push({ name : 'hspcategory form', params : { id : item._id }})">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn class="ma-0" icon text color="accent"
                             @click="$router.push({ name : 'hspcategory form', params : { id : 'new' }, query : { parent : item._id }})">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>

                  </v-treeview>
                </v-col>
                <v-col cols="12" md="6" lg="8" xl="9" v-if="selected">
                  <h2 class="title mb-2">{{ L('Photos with') }} "{{ activeCategoryName }}"</h2>
                  <v-text-field class="mb-0" v-model="photoFilter" :label="L('Filter')" filled rounded
                                dense hide-details clearable></v-text-field>
                  <v-list two-line dense>
                    <template v-for="(item, index) in currentPage">
                      <v-divider inset :key="index"></v-divider>
                      <v-list-item :key="item._id" @click="">
                        <v-list-item-avatar>
                          <img :src="item.imgf_url + '?cmd=thumb'">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="item.title.en"></v-list-item-title>
                          <v-list-item-subtitle>
                            <span>{{ item.author.full_name }}</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon text color="accent"
                                 @click="$router.push(`/photos/${item._id}`)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                  <v-btn icon text :disabled="page === 0" @click="page--">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span>page {{ page + 1 }} of {{ Math.max(numPages, 1) }}</span>
                  <v-btn icon text :disabled="page >= numPages-1" @click="page++">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import config from "../config";
import utils from "../utils";

export default {
  data() {
    return {
      photoFilter: '',
      nameSearch: null,
      opened: [],
      active: [],
      photosData: [],
      limit: 10,
      page: 0
    }
  },
  watch: {
    active: {
      handler() {
        this.loadPhotosOf(this.selected)
      }
    },
    opened: {
      handler() {
        this.saveFiltersInSession();
      }
    }
  },
  computed: {
    ...mapGetters(['profile', 'hspcategories', 'is_admin']),

    activeCategoryName() {
      if (!this.hspcategories || !this.selected) return "";
      for (let i = 0; i < this.hspcategories.length; i++) {
        if (this.hspcategories[i]._id === this.selected) {
          return this.hspcategories[i].name[this.language] || this.hspcategories[i].name.en || this.hspcategories[i].name.it;
        }
      }
      return ""
    },
    numPages() {
      return Math.ceil(this.photosData.length / this.limit)
    },
    currentPage() {
      const index = this.page * this.limit;
      return this.photosData.slice(index, index + this.limit);
    },
    selected() {
      if (!this.active.length) return undefined;
      return this.active[0];
    },
    items() {
      let toOpen = [];
      if (!this.hspcategories) return [];
      if (this.nameSearch && this.nameSearch.length >= 3) {
        this.opened = []
      }
      let filter = this.hspcategories.filter((item) => {
        let matched = true;
        if (this.nameSearch && this.nameSearch.length >= 3) {
          let stringMatch = utils.stringSearch(item.name.en, this.nameSearch);
          let childMatch = utils.stringInChild(this.hspcategories, item._id, 'en', this.nameSearch);
          matched = stringMatch || childMatch;
          if (stringMatch) {
            item.highlighted = true;
          } else {
            item.highlighted = false;
          }
          if (childMatch) {
            toOpen.push(item._id)
          }
        } else {
          item.highlighted = false;
        }
        return matched;
      });
      if (toOpen.length) {
        this.opened = toOpen;
      }
      return utils.hspcategoriesToTree(filter)
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'get', 'delete', 'refreshCollection']),
    L(key) {
      return key;
    },

    loadPhotosOf(id) {
      this.photosData = [];
      if (!id) {
        return
      }

      let idQuery = id + "/photos"

      this.get({collection: 'hspcategories', id: idQuery}).then((reply) => {
        if (reply.status === 'success' && reply.data) {
          this.photosData = reply.data;
        }
      }).catch((err) => {
        if (config.debug) console.error("Errore al caricamento delle opere della categoria", err);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    requestDelete(id, name) {
      this.requestConfirm({
        title: 'Delete request', message: 'Are you sure to delete this item permanently?', callback: (accepted) => {
          if (accepted) {
            this.delete({collection: 'hspcategories', id}).then(() => {
              this.refreshCategories();
              this.sendSuccess({message: 'Item deleted!'});
            }).catch((err) => {
              let message = err.message ? err.message : 'there was an error while removing the item';
              this.sendError({message});
              if (err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });
    },

    loadFiltersInSession() {
      let filters = sessionStorage.hspcategoriesQuery ? JSON.parse(sessionStorage.hspcategoriesQuery) : {};
      if (filters.name) {
        this.nameSearch = filters.name;
      }
      if (filters.opened) {
        this.opened = filters.opened;
      }
    },
    saveFiltersInSession() {
      sessionStorage.hspcategoriesQuery = JSON.stringify(
          {
            name: this.nameSearch,
            opened: this.opened
          }
      )
      if (this.selected) {
        this.loadPhotosOf(this.selected)
      }
    }
  },
  mounted() {
    this.refreshCollection({collection: 'hspcategories', sort: '+name.en'});
    this.loadFiltersInSession();
  }
}
</script>