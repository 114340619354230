var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('h2',{staticClass:"display-1 ml-4"},[_vm._v("Images")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary--text",attrs:{"color":"accent","small":""},on:{"click":function($event){return _vm.$router.push('/import/imgfs')}}},[_vm._v("Import")]),_c('v-btn',{staticClass:"secondary--text ml-2",attrs:{"color":"accent","small":""},on:{"click":function($event){return _vm.$router.push('/imgfs/new')}}},[_vm._v("New")])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","hide-details":"","label":"Search by title"},on:{"input":_vm.applyFilter},model:{value:(_vm.titleSearch),callback:function ($$v) {_vm.titleSearch=$$v},expression:"titleSearch"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-select',{attrs:{"filled":"","rounded":"","dense":"","hide-details":"","label":"Conversion status","items":_vm.statusOptions,"clearable":""},on:{"change":_vm.applyFilter},model:{value:(_vm.statusSearch),callback:function ($$v) {_vm.statusSearch=$$v},expression:"statusSearch"}})],1)],1),_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.count,"loading":_vm.tableLoading,"no-data-text":"There are no items yet","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.thumb",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'./#/imgfs/'+item._id}},[_c('v-img',{staticStyle:{"background":"#000"},attrs:{"src":item.url + '?cmd=thumb',"alt":item.url,"contain":"","width":"80","height":"80"}})],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'./#/imgfs/'+item._id,"title":item.url}},[_c('b',[_vm._v(_vm._s(item.title))])])]}},{key:"item.photos",fn:function(ref){
var item = ref.item;
return _vm._l((item.photos),function(art){return _c('a',{key:item._id+art._id,staticClass:"d-block",attrs:{"href":'./#/photos/'+art._id,"title":art.title}},[_c('b',[_vm._v(_vm._s(art.title))])])})}},{key:"item.imgf_size",fn:function(ref){
var item = ref.item;
return [(item.imgf_exists)?_c('span',[_vm._v(_vm._s((item.imgf_size / 1024 / 1024).toFixed(2))+" MB")]):_c('span',[_vm._v("-")])]}},{key:"item.source_size",fn:function(ref){
var item = ref.item;
return [(item.source_exists)?_c('span',[_vm._v(_vm._s((item.source_size / 1024 / 1024).toFixed(2))+" MB")]):_c('span',[_vm._v("-")])]}},{key:"item.conversion_status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.conversionStatusClass(item.conversion_status)},[_vm._v(_vm._s(item.conversion_status ? item.conversion_status : '-'))]),(item.conversion_status === 'fail' && !_vm.is_translator)?_c('v-btn',{attrs:{"text":"","icon":"","color":"accent"},on:{"click":function($event){return _vm.retryConversion(item._id)}}},[_c('v-icon',[_vm._v("refresh")])],1):_vm._e()]}},{key:"item.gen",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.$router.push(("/photos/new?imgf=" + (item._id)))}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-auto-fix")])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"accent","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.$router.push({ name : 'imgf form', params : { id : item._id }})}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-edit")])],1)]}},{key:"item.remove_source",fn:function(ref){
var item = ref.item;
return [(item.source !== '')?_c('v-btn',{attrs:{"color":"error","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.requestSourceDelete(item._id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1):_vm._e()]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.requestDelete(item._id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }