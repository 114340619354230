<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Photos</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" @click="$router.push('/photos/new')">New</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-row row wrap>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field filled rounded dense hide-details v-model="titleSearch" label="Search by title"
                                @input="applyFilter"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-autocomplete filled rounded dense hide-details v-model="authorSearch" clearable
                                  :items="authorsOptions" label="Search by author"
                                  @change="applyFilter"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-autocomplete filled rounded dense hide-details v-model="continentSearch" clearable
                                  :items="continentsOptions" label="Search by continent"
                                  @change="applyFilter"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-autocomplete filled rounded dense hide-details v-model="sectionSearch" clearable
                                  :items="sectionsOptions" label="Search by section"
                                  @change="applyFilter"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-select filled rounded dense hide-details v-model="pubSearch" clearable :items="pubOptions"
                            label="Published" @change="applyFilter"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"><h4>Bulk actions</h4></v-col>
                <v-col cols="12" class="mt-0 pt-0">
                  <v-btn color="accent" small class="secondary--text flex-grow-0 flex-shrink-1" @click="selectAll()">
                    Select all
                  </v-btn>
                  <v-btn color="accent" small class="secondary--text flex-grow-0 flex-shrink-1 ml-2"
                         @click="setPub(true)">Publish selected
                  </v-btn>
                  <v-btn color="accent" small class="secondary--text flex-grow-0 flex-shrink-1 ml-2"
                         @click="setPub(false)">Unpublish selected
                  </v-btn>
                </v-col>

              </v-row>
              <v-row row wrap>

                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination"
                                :server-items-length="count" :loading="tableLoading"
                                no-data-text="There are no items yet" :footer-props="footerProps">
                    <template v-slot:item.qr="{ item }">
                      <v-img style="cursor: pointer" @click="downloadQr(item._id)" contain width="80" height="80"
                             :src="'https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fngs.artcentrica.com%2Fphotos%2F'+item._id+'&chs=80x80&choe=UTF-8&chld=L|2'"
                             alt=''></v-img>
                    </template>
                    <template v-slot:item.select="{ item }">
                      <v-checkbox v-model="selection[item._id]" :key="item._id + 'check' + refreshKey"/>
                    </template>
                    <template v-slot:item.imgf_url="{ item }">
                      <a class="ma-0" :href="'./#/photos/'+item._id" v-if="item.imgf_url">
                        <v-img :src="forceHttps(item.imgf_url) + '?cmd=thumb'" contain width="80" height="80"
                               class="black"/>
                      </a>
                    </template>
                    <template v-slot:item.title.en="{ item }">
                      <a :href="'./#/photos/'+item._id"><b>{{ item.title ? item.title.en || '-' : '-' }}</b></a>
                    </template>
                    <template v-slot:item.author="{ item }">
                      <a :href="'./#/authors/'+item.author_id" v-if="item.author">{{ item.author.full_name || '-' }}</a>
                    </template>

                    <template v-slot:item.continent="{ item }">
                      <a :href="'./#/continents/'+item.continent_id"
                         v-if="item.continent">{{ item.continent.en || '-' }}</a>
                    </template>
                    <template v-slot:item.section="{ item }">
                      <a :href="'./#/sections/'+item.section_id" v-if="item.section">{{ item.section.order }}.
                        {{ item.section.name.en }}</a>
                    </template>
                    <template v-slot:item.country="{ item }">
                      <a :href="'./#/countries/'+item.country_id" v-if="item.country">{{ item.country.en || '-' }}</a>
                    </template>
                    <template v-slot:item.position="{ item }">
                      <span v-if="item.position && item.position.coordinates">lng: {{ item.position.coordinates[0] }}, lat: {{
                          item.position.coordinates[1]
                        }}</span>
                      <span v-else>-</span>
                    </template>
                    <template v-slot:item.biodiversities="{ item }">
                      <span v-if="item.biodiversities" v-for="(b, i) in item.biodiversities"
                            :key="item._id + 'bio' + i">
                        <span v-if="i > 0">, </span>
                        <a :href="'./#/biodiversity/'+b._id">{{ b.name.en || '-' }}</a>
                      </span>
                    </template>

                    <template v-slot:item.bioregion_desc="{ item }">
                      <span v-if="item.bioregion_desc && item.bioregion_desc.en" class="font-weight-bold success--text">Yes</span>
                      <span v-else class="font-weight-bold error--text">No</span>
                    </template>
                    <template v-slot:item.categories="{ item }">
                      <span v-if="item.categories" v-for="(c, i) in item.categories" :key="item._id + 'ctg' + i">
                        <span v-if="i > 0">, </span>
                        <a :href="'./#/categories/'+c._id">{{ c.name.en || '-' }}</a>
                      </span>
                    </template>
                    <template v-slot:item.likes="{ item }">
                      <v-icon :color="numLikesOf(item._id) > 0 ? 'error' : ''">mdi-heart</v-icon>
                      &nbsp{{ numLikesOf(item._id) }}
                    </template>
                    <template v-slot:item.pub="{ item }">
                      <strong class="success--text" v-if="item.pub">Yes</strong>
                      <strong class="error--text" v-else>No</strong>
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.hsp="{ item }">
                      <v-btn color="accent" small rounded dark text
                             @click="$router.push(`/photos/${item._id}/hotspots` )">
                        <v-icon dark>mdi-shape-square-plus</v-icon>
                        <span class="font-weight-bold black--text ml-3">{{ numHsp(item._id) }}</span>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import config from "../config";
import _ from "lodash";
import utils from "../utils";
import log from "@/log";

export default {
  data() {
    return {
      pubSearch: null,
      pubOptions: [
        {text: "Show published only", value: "true"},
        {text: "Show unpublished only", value: "false"}
      ],
      refreshKey: 0,
      selection: {},
      editingItem: null,
      refreshTimeout: -1,
      titleSearch: null,
      authorSearch: null,
      continentSearch: null,
      sectionSearch: null,
      tableLoading: false,
      footerProps: {
        "items-per-page-text": "no. items per page",
        "items-per-page-options": [20, 50, 100],
        "items-per-page": 20
      },
      count: 0,
      items: [],
      headers: [
        {text: "", sortable: false, align: 'left', value: 'select', width: 60},
        /*{text: "Num", sortable: true, align: 'left', value: 'code', width: 80},*/
        /*{text: "QR", sortable: false, align: 'left', value: 'qr', width: 80},*/
        {text: "Image", sortable: false, align: 'left', value: 'imgf_url'},
        {text: "Title", sortable: true, align: 'left', value: 'title.en'},
        {text: "Position", sortable: false, align: 'left', value: 'position'},
        {text: "Author", sortable: false, align: 'left', value: 'author'},
        {text: "Continent", sortable: false, align: 'left', value: 'continent'},
        {text: "Section", sortable: false, align: 'left', value: 'section'},
        {text: "Bioregion", sortable: false, align: 'left', value: 'biodiversities'},
        {text: "Bioregion desc", sortable: false, align: 'left', value: 'bioregion_desc'},
        {text: "Categories", sortable: false, align: 'left', value: 'categories'},
        {text: "Likes", sortable: false, align: 'left', value: 'likes', width: 80},
        {text: "Published", sortable: true, align: 'left', value: 'pub'},
        {text: "Hotspots", sortable: false, align: 'center', value: 'hsp'},
        {text: "Delete", sortable: false, align: 'center', value: 'remove', width: 50},
      ],
      pagination: {
        sortBy: ["title.en"],
        sortDesc: [false]
      },
      query: {},
      likes: {}
    }
  },
  watch: {
    pagination: {
      handler() {
        sessionStorage.photosPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['token', 'authors', 'continents', 'sections', 'hotspots']),
    authorsOptions() {
      return this.authors.map((item) => {
        return {text: item.full_name || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    continentsOptions() {
      return this.continents.map((item) => {
        return {text: item.name.en || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    sectionsOptions() {
      return this.sections.map((item) => {
        return {text: `${item.order}. ${item.name.en}` || '-', value: item._id}
      }).sort(utils.sortFunction('order'))
    },
    server() {
      return config.server;
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'get', 'update', 'delete', 'refreshCollection', 'upload']),
    numLikesOf(id) {
      return this.likes[id] ? this.likes[id].likes : 0;
    },
    numHsp(id) {
      let filter = this.hotspots.filter(item => item.photo_id === id);
      return filter.length;
    },
    downloadQr(id) {
      const url = `${config.server}/api/photos/${id}/qr?y=true&t=${this.token}`;
      window.open(url, '_blank');
    },
    selectAll() {
      let pairs = _.toPairs(this.selection);
      if (pairs.length < this.items.length) {
        for (let i = 0; i < this.items.length; i++) {
          this.selection[this.items[i]._id] = true;
        }
      } else {
        this.selection = {}
      }
      this.refreshKey++;
    },
    async setPub(value) {
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        if (!this.selection[item._id] || item.pub === value) continue;
        item.pub = value;
        try {
          await this.update({collection: 'photos', data: item})
          this.selection[item._id] = false
          this.refreshKey++
        } catch (e) {
          console.log(e)
          this.sendError({message: "there was an error and the request could not be completed"})
        }
      }
      this.sendSuccess({message: 'publication status updated'})
      this.refresh()
    },
    forceHttps(url) {
      if (url.indexOf('https://') === -1) {
        url = url.replace('http://', 'https://')
      }
      return url;
    },
    async loadLikes() {
      try {
        let reply = await this.get({collection: 'likes'})
        this.likes = _.keyBy(reply.data, '_id');
      } catch (e) {
        log.d(e)
      }
    },
    applyFilter() {
      this.query = {};
      this.query.title = this.titleSearch ? this.titleSearch : undefined;
      this.query.author = this.authorSearch ? this.authorSearch : undefined;
      this.query.continent = this.continentSearch ? this.continentSearch : undefined;
      this.query.section = this.sectionSearch ? this.sectionSearch : undefined;
      this.query.pub = this.pubSearch ? this.pubSearch : undefined;

      this.pagination.page = 1;
      sessionStorage.photosQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    requestDelete(id) {
      this.requestConfirm({
        title: 'Delete request', message: 'Are you sure to permanently delete this item?', callback: (accepted) => {
          if (accepted) {
            this.delete({collection: 'photos', id}).then(() => {
              this.refresh();
              this.sendSuccess({message: 'Item deleted!'});
            }).catch((err) => {
              let message = err.message ? err.message : "there was an error and the request could not be completed";
              this.sendError({message});
              log.d("there was an error while removing photo", err);
              if (err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }
      });
    },
    refresh() {
      this.tableLoading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.pagination;
      let sort = null;
      if (sortBy && sortBy.length) {
        sort = sortBy[0];
        if (sortDesc && sortDesc[0]) {
          sort = encodeURIComponent('-' + sort);
        } else {
          sort = encodeURIComponent('+' + sort);
        }
      }

      let filter = {
        query: this.query,
        limit: itemsPerPage,
        page,
        sort
      };

      this.load({collection: 'photos', filter}).then((reply) => {
        this.tableLoading = false;
        if (reply.status === "success") {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err) => {
        this.tableLoading = false;
        this.sendError({message: "there was an error while loading data"});
        log.d("there was an error while loading photos: " + err);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
  },
  mounted() {
    if (sessionStorage.photosQuery) {
      this.query = JSON.parse(sessionStorage.photosQuery);
      if (this.query.title) {
        this.titleSearch = this.query.title
      }
      if (this.query.author) {
        this.authorSearch = this.query.author
      }
      if (this.query.continent) {
        this.continentSearch = this.query.continent
      }
      if (this.query.section) {
        this.sectionSearch = this.query.section
      }
      if (this.query.pub) {
        this.pubSearch = this.query.pub
      }
    }
    if (sessionStorage.photosPagination) {
      this.pagination = JSON.parse(sessionStorage.photosPagination);
    }

    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
    this.refreshCollection({collection: 'authors'});
    this.refreshCollection({collection: 'continents'});
    this.refreshCollection({collection: 'countries'});
    this.refreshCollection({collection: 'sections'});
    this.refreshCollection({collection: 'categories'});
    this.refreshCollection({collection: 'biodiversities'});
    this.refreshCollection({collection: 'hotspots'});
    this.loadLikes().then(() => {
    });
  }
}
</script>
