<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12">
        <v-card >
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Users</h2>
            <v-spacer />
            <v-btn color="accent" class="secondary--text" @click="$router.push('/users/new')">New</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="nameSearch" label="Search by name" @input="applyFilter"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="emailSearch" type="email" label="Search by email" @input="applyFilter"></v-text-field>
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                no-data-text="There are no items yet" :footer-props="footerProps" >

                    <template v-slot:item.email="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a @click="$router.push(`/users/${item._id}`)" class="text-decoration-underline" v-bind="attrs" v-on="on"><b>{{ item.email }}</b></a>
                        </template>
                        <span>ID: {{item._id}}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.role="{ item }">
                      <span class="font-weight-bold purple--text" v-if="item.role === 'root'">Root</span>
                      <span class="font-weight-bold red--text" v-else-if="item.role === 'admin'">Administrator</span>
                      <span class="font-weight-bold orange--text" v-else-if="item.role === 'editor'">Editor</span>
                      <span class="font-weight-bold green--text" v-else-if="item.role === 'user'">User</span>
                      <span class="font-weight-bold grey--text" v-else>{{role}}</span>
                    </template>

                    <template v-slot:item.edit="{ item }">
                      <v-btn color="accent" dark icon v-on:click="$router.push({ name : 'user form', params : { id : item._id }})">
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn :disabled="item.role === 'root'" color="error" dark icon v-on:click="requestDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import utils from "../utils";
import log from "../log";
export default {
  data () {
    return {
      refreshTimeout : -1,
      nameSearch : null,
      emailSearch : null,
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'Email', sortable: true, align: 'left', value : 'email' },
        { text : 'Name', sortable: true, align: 'left', value : 'name' },
        { text : 'Surname', sortable: true, align: 'left', value : 'surname' },
        { text : 'Role', sortable: true, align: 'left', value : 'role' },
        { text : 'Delete', sortable: false, align: 'center', value : 'remove', width : 50 },
      ],
      footerProps : {
        "items-per-page-text" :  "no. items per page",
        "items-per-page-options" : [10,20,50],
        "items-per-page" : 10
      },
      pagination: {},
      query : {}
    }
  },
  watch: {
    pagination: {
      handler () {
        sessionStorage.usersPagination = JSON.stringify(this.pagination)
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  computed : {
    ...mapGetters(['is_admin'])
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','refreshCollection']),
    formatDateTime( date ) {
      return utils.formatDateTime(date);
    },
    applyFilter() {
      this.query = {};
      this.query.fullname = this.nameSearch ? this.nameSearch : undefined;
      this.query.email = this.emailSearch ? this.emailSearch : undefined;
      this.pagination.page = 1
      sessionStorage.usersQuery = JSON.stringify(this.query)
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Delete request', message : 'Are you sure to permanently delete this user?', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'users', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'User deleted!'});
            }).catch((err)=>{
              let message = err.message ? err.message : "there was an error and the request could not be completed";
              this.sendError({ message });
              log.d("there was an error while removing this user",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;

      if(sortBy && sortBy.length) {
        sort = sortBy[0];
        if(sortDesc && sortDesc[0]) {
          sort = encodeURIComponent('-' + sort);
        } else {
          sort = encodeURIComponent('+' + sort);
        }
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page,
        sort
      };

      this.load({ collection : 'users', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"there was an error while loading data"});
        log.d("there was an error while loading utenti: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.usersQuery) {
      this.query = JSON.parse(sessionStorage.usersQuery)
      this.nameSearch = this.query.fullname ? this.query.fullname : ''
      this.emailSearch = this.query.email ? this.query.email : ''
    }
    if(sessionStorage.usersPagination) {
      this.pagination = JSON.parse(sessionStorage.usersPagination);
    }
    this.refresh();
  }
}
</script>
