<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small v-if="editingItem._id" @click="goToPhotosOfThis()">
              Photos of this author
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Full Name*" v-model="editingItem.full_name"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Name*" v-model="editingItem.name"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Surname*" v-model="editingItem.surname"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Initial*" v-model="editingItem.initial"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Born place" v-model="editingItem.born_place"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Born date" v-model="editingItem.born_date"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Instagram name"
                                  v-model="editingItem.instagram_name"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Instagram url"
                                  v-model="editingItem.instagram_url"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-textarea outlined dense label="Biography" v-model="editingItem.bio.en"></v-textarea>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-textarea outlined dense label="Questionary Result"
                                v-model="editingItem.questionary_result.en"></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete outlined dense label="Media" multiple :items="mediaOptions"
                                    v-model="editingItem.media_ids" chips deletable-chips
                                    small-chips></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-switch class="min-content" label="Published" v-model="editingItem.pub"></v-switch>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import log from '../log';
import {mapActions, mapGetters} from 'vuex';
import rules from "../utils/rules";
import utils from "@/utils";

export default {
  data() {
    return {
      loading: false,
      editingItem: {
        name: '',
        surname: '',
        full_name: '',
        initial: '',
        bio: {
          en: ''
        },
        questionary_result: {en: ''},
        born_place: '',
        born_date: '',
        instagram_name: '',
        instagram_url: '',
        media_ids: [],
        pub: false
      },
      rules: {
        ...rules,
      }
    }
  },
  computed: {
    ...mapGetters(['media']),
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New photographer' : 'Edit photographer';
    },
    mediaOptions() {
      return this.media.map((item) => {
        return {text: item.title.en || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert', 'refreshCollection']),
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'authors', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          if (!reply.data.questionary_result) reply.data.questionary_result = {en: ''};
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    goToPhotosOfThis() {
      sessionStorage.photosQuery = JSON.stringify({author: this.editingItem._id});
      sessionStorage.removeItem('photosPagination');
      this.$router.push('/photos')
    },
    async save(afterAction) {
      let updating = this.updating;

      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      let saveFn = updating ? this.update : this.insert;

      try {
        let {data} = await saveFn({collection: 'authors', data: this.editingItem});
        this.sendSuccess({message: "Data saved!"});
        this.loading = false;
        switch (afterAction) {
          case 1:
            await this.$router.replace('/authors')
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/authors/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              name: '',
              surname: '',
              full_name: '',
              initial: '',
              bio: {
                en: ''
              },
              questionary_result: {en: ''},
              born_place: '',
              born_date: '',
              instagram_name: '',
              instagram_url: '',
              media_ids: [],
              pub: false
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/authors/new`);
            }
            break
        }

      } catch (err) {
        this.loading = false;
        log.d("Save author error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    this.refreshCollection({collection: 'media'});
    if (this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>
