<template>
  <v-container fluid class="fill-height secondary">
    <div class="d-flex justify-center align-center fill-height full-width">
      <v-row class="d-flex justify-center" style="max-width: 360px">
        <v-col cols="12" class="white--text" style="position: relative"  >
          <h1 class="display-1 full-width text-center">ArtCentrica NGS<br><span class="title">ADMIN</span></h1>
        </v-col>
        <v-col cols="12">
          <v-form @submit.prevent="onSubmit()">
            <v-text-field
                dark
                color="accent"
                v-model="email"
                label="Email"
                name="email"
                type="email"
                outlined
                :error="emailError"
                :error-messages="emailErrorMessage"
            ></v-text-field>
            <v-text-field
                dark
                color="accent"
                v-model="password"
                name="password"
                label="Password"
                outlined
                :error="passwordError"
                :error-messages="passwordErrorMessage"
                id="password"
                :type="showPwd ? 'text' : 'password'" :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPwd = !showPwd"></v-text-field>

            <v-btn class="flex-grow-1 flex-shrink-0 full-width secondary--text" color="accent" type="submit" :loading="loading">Login</v-btn>
          </v-form>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import log from '../log'
export default {
  name: "Login",
  data() {
    return {
      loading : false,
      showPwd : false,
      email : '',
      password: '',
      emailError: false,
      passwordError: false,
    }
  },
  computed : {
    ...mapGetters(['profile']),
    isLoggedIn() {
      return !!this.profile;
    },
    emailErrorMessage() {
      return this.emailError ? ['insert a valid mail'] : []
    },
    passwordErrorMessage() {
      return this.passwordError ? ["the password is wrong or the account does not exists"] : []
    },
  },
  methods : {
    ...mapActions(['login','logout','setToken','setProfile','sendError']),
    onError(e) {
      if(e) {
        log.d(e)
        this.passwordError = true;
        this.loading = false;
        this.logout()
      }
    },
    async onSubmit() {
      this.emailError = this.email === '';
      this.passwordError = this.password === '';
      if(this.passwordError || this.emailError) return;
      this.loading = true;
      try {
        let reply = await this.login({email: this.email, password: this.password})
        if(reply.data.user.role === "user") {
          this.sendError({message: "You don't have permissions to use this panel!"});
          this.loading = false;
          return
        }
        this.setToken({token : reply.data.token })
        this.setProfile({profile : reply.data.user })
        this.loading = false;
        await this.$router.replace('/photos');
      } catch (err) {
        this.onError(err)
      }
    }
  },
  mounted() {
    if(this.isLoggedIn) {
      this.$router.replace('/photos');
    }
  }
}
</script>
