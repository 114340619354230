<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ L(title) }}</h2>
            <v-spacer/>
            <v-btn text color="accent" small @click="save(1)">{{ L('Save') }}</v-btn>
            <v-btn text color="accent" small @click="save(2)">{{ L('Save and continue') }}</v-btn>
            <v-btn text color="accent" small @click="save(3)">{{ L('Save and add') }}</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-row row wrap>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-autocomplete outlined dense :label="L('Parent category')" chip clearable
                                  v-model="editingItem.parent_id" :items="categoriesOptions"></v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field outlined dense :label="L('Category name')+'*'" v-model="editingItem.name.en"
                                :error="nameError" :error-messages="requiredErrorMessages(nameError)"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text color="accent" small @click="save(1)">{{ L('Save') }}</v-btn>
            <v-btn text color="accent" small @click="save(2)">{{ L('Save and continue') }}</v-btn>
            <v-btn text color="accent" small @click="save(3)">{{ L('Save and add') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import config from "../config";
import _ from 'lodash'
import utils from "../utils";

export default {
  data() {
    return {
      editingItem: {
        _id: null,
        name: {en: ''},
        parent_id: ''
      },
      nameError: false,
    }
  },
  computed: {
    ...mapGetters(['token', 'profile', 'hspcategories']),
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New hotspot category' : 'Edit hotspot category';
    },
    loading: {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.state.loading = val;
      }
    },
    categoriesOptions() {
      return this.hspcategories.map((item) => {
        let text = item.name.en
        return {text, value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert', 'refreshCollection']),
    L(key) {
      return key;
    },
    requiredErrorMessages(err) {
      if (err) return [this.L('required field')];
      return []
    },

    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'hspcategories', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : 'there was an error while loading data';
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    clearErrors() {
      this.nameError = false;
    },
    async save(afterAction) {
      this.clearErrors();
      let updating = this.updating;

      if (!this.editingItem.name.en) {
        this.nameError = true;
        return;
      }

      let saveFn = updating ? this.update : this.insert;
      try {
        let {data} = await saveFn({collection: 'hspcategories', data: this.editingItem});
        this.sendSuccess({message: "Category saved"});
        switch (afterAction) {
          case 1:
            await this.$router.replace('/hspcategories')
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/hspcategories/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              _id: null,
              name: {en: ''},
              parent_id: ''
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/hspcategories/new`);
            }
            break
        }
      } catch (err) {
        let message = err.message ? err.message : 'there was an error while saving data';
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.loadData()
    }
    if (this.$route.query.parent) {
      this.editingItem.parent_id = this.$route.query.parent;
    }
    this.refreshCollection({collection: 'hspcategories'});
  }
}
</script>
