<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Survey results</h2>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-row row wrap>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field filled rounded dense hide-details v-model="fromSearch" label="Date from" type="date"
                                clearable @input="applyFilter"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field filled rounded dense hide-details v-model="toSearch" label="Date to" type="date"
                                clearable @input="applyFilter"></v-text-field>
                </v-col>
              </v-row>
              <v-row row wrap>
                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination"
                                :server-items-length="count" :loading="tableLoading"
                                no-data-text="There are no items yet" :footer-props="footerProps">
                    <template v-slot:item.date="{ item }">
                      <a class="text-decoration-underline" @click="showDetails(item)"><b>{{
                          formatDate(item.date)
                        }}</b></a>
                    </template>

                    <template v-slot:item.choices="{ item }">
                      <ul>
                        <li v-for="choice in item.choices" :key="item_id + '_' + choice._id">{{ choice.text.en }}</li>
                      </ul>
                    </template>

                    <template v-slot:item.l1choices="{ item }">
                      <ul>
                        <li v-for="choice in item.l1choices" :key="item_id + '_l1_' + choice._id">{{ choice.order + 1 }}
                          -
                          {{ getSlideTitle(choice.slide_id) }}
                        </li>
                      </ul>
                    </template>
                    <template v-slot:item.l2choices="{ item }">
                      <ul>
                        <li v-for="choice in item.l2choices" :key="item_id + '_l2_' + choice._id">{{ choice.order + 1 }}
                          -
                          {{ getSlideTitle(choice.slide_id) }}
                        </li>
                      </ul>
                    </template>
                    <template v-slot:item.l3choices="{ item }">
                      <ul>
                        <li v-for="choice in item.l3choices" :key="item_id + '_l3_' + choice._id">{{ choice.order + 1 }}
                          -
                          {{ getSlideTitle(choice.slide_id) }}
                        </li>
                      </ul>
                    </template>

                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import log from "../log";
import utils from "@/utils";

export default {
  data() {
    return {
      refreshTimeout: -1,
      fromSearch: null,
      toSearch: null,
      tableLoading: false,
      footerProps: {
        "items-per-page-text": "no. items per page",
        "items-per-page-options": [20, 50, 100],
        "items-per-page": 20
      },
      selection: {},
      refreshKey: 0,
      count: 0,
      items: [],

      pagination: {
        sortBy: ["name.en"],
        sortDesc: [false]
      },
      query: {}
    }
  },
  watch: {
    pagination: {
      handler() {
        sessionStorage.surResPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['is_admin', 'slides']),
    headers() {
      let items = [
        {text: "Date", sortable: true, align: 'left', value: 'date'},
        {text: "IP address", sortable: false, align: 'left', value: 'ip'},
        {text: "Lesson 1 choices", sortable: false, align: 'left', value: 'l1choices'},
        {text: "Lesson 2 choices", sortable: false, align: 'left', value: 'l2choices'},
        {text: "Lesson 3 choices", sortable: false, align: 'left', value: 'l3choices'},
        {text: "Survey Choices", sortable: false, align: 'left', value: 'choices'},
      ];

      if (this.is_admin) {
        items.push({text: "Delete", sortable: false, align: 'center', value: 'remove', width: 50})
      }
      return items;


    }
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'update', 'refreshCollection']),
    applyFilter() {
      this.query = {};
      this.query.from = this.fromSearch ? this.fromSearch : undefined;
      this.query.to = this.toSearch ? this.toSearch : undefined;
      this.pagination.page = 1;
      sessionStorage.surResQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    getSlideTitle(id) {
      let slide = utils.getObjectInArray(this.slides, '_id', id)
      return slide ? slide.title.en : '-';
    },
    formatDate(date) {
      return utils.formatDateTime(date)
    },
    showDetails(item) {

    },
    requestDelete(id) {
      this.requestConfirm({
        title: 'Delete request', message: 'Are you sure to permanently delete this item?', callback: (accepted) => {
          if (accepted) {
            this.delete({collection: 'survey/results', id}).then(() => {
              this.refresh();
              this.sendSuccess({message: 'Item deleted!'});
            }).catch((err) => {
              let message = err.message ? err.message : "there was an error and the request could not be completed";
              this.sendError({message});
              log.d("there was an error while removing survey result", err);
              if (err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }
      });
    },
    refresh() {
      this.tableLoading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.pagination;
      let sort = null;
      if (sortBy && sortBy.length) {
        sort = sortBy[0];
        if (sortDesc && sortDesc[0]) {
          sort = encodeURIComponent('-' + sort);
        } else {
          sort = encodeURIComponent('+' + sort);
        }
      }

      let filter = {
        query: this.query,
        limit: itemsPerPage,
        page,
        sort
      };

      this.load({collection: 'survey/results', filter}).then((reply) => {
        this.tableLoading = false;
        if (reply.status === "success") {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err) => {
        this.tableLoading = false;
        this.sendError({message: "there was an error while loading data"});
        log.d("there was an error while loading records: " + err);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if (sessionStorage.surResQuery) {
      this.query = JSON.parse(sessionStorage.surResQuery);
      this.fromSearch = this.query.from ? this.query.from : null;
      this.toSearch = this.query.to ? this.query.to : null;
    }
    if (sessionStorage.surResPagination) {
      this.pagination = JSON.parse(sessionStorage.surResPagination);
    }

    this.refreshCollection({collection: 'slides'})
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
  }
}
</script>
