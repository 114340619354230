<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <v-col cols="3" md="2" lg="1">
                    <v-text-field outlined dense label="Order*" v-model="editingItem.order"
                                  :rules="[rules.required, rules.minInt(1)]"></v-text-field>
                  </v-col>
                  <v-col cols="9" md="10" lg="11">
                    <v-text-field outlined dense label="Name*" v-model="editingItem.name.en"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="Icon url" v-model="editingItem.icon"
                                  hint="fill either this field with a valid url or the icon file input to set the category icon"
                                  persistent-hint></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-file-input outlined dense label="Icon file" v-model="icon"
                                  hint="fill either this file input or the icon url field with a valid url to set the category icon"
                                  persistent-hint accept=".png"></v-file-input>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="POI icon url" v-model="editingItem.poi_icon"
                                  hint="fill either this field with a valid url or the file input to set the minimap icon"
                                  persistent-hint></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-file-input outlined dense label="POI icon file" v-model="poiIcon"
                                  hint="fill either this file input or the url field with a valid url to set the minimap icon"
                                  persistent-hint accept=".png"></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import log from '../log';
import {mapActions} from 'vuex';
import rules from "../utils/rules";
import utils from "@/utils";

export default {
  data() {
    return {
      loading: false,
      editingItem: {
        order: 0,
        name: {en: ''},
        icon: '',
        poi_icon: '',
      },
      icon: null,
      poiIcon: null,
      rules: {
        ...rules,
      }
    }
  },
  computed: {
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New category' : 'Edit category';
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert', 'upload']),
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'categories', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    async save(afterAction) {
      let updating = this.updating;
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;

      let saveFn = updating ? this.update : this.insert;
      this.editingItem.order = utils.toInt(this.editingItem.order, 1)
      try {
        let {data} = await saveFn({collection: 'categories', data: this.editingItem});

        if (this.icon) {
          let formData = new FormData();
          formData.append("file", this.icon);
          let reply = await this.upload({where: `categories/${data._id}/icon`, formData})
          data = reply.data;
          this.icon = null;
        }
        if (this.poiIcon) {
          let formData = new FormData();
          formData.append("file", this.poiIcon);
          let reply = await this.upload({where: `categories/${data._id}/poi`, formData})
          data = reply.data;
          this.poiIcon = null;
        }
        this.loading = false;
        this.sendSuccess({message: "Data saved!"});
        switch (afterAction) {
          case 1:
            await this.$router.replace('/categories')
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/categories/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              order: 0,
              name: {en: ''},
              icon: '',
              poi_icon: '',
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/categories/new`);
            }
            break
        }

      } catch (err) {
        this.loading = false;
        log.d("Save category error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>
