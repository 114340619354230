import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
import log from "../log";
import router from "../router";

Vue.use(Vuex);

const state = {
    token: sessionStorage.token || null,
    profile: null,
    dialogMessage: '',
    successDialog: false,
    warningDialog: false,
    errorDialog: false,
    infoDialog: false,
    confirmDialog: false,
    confirmDialogTitle: 'Richiesta conferma',
    confirmDialogMessage: 'Sei sicuro?',
    confirmDialogCallback: null,
    media: [],
    photos: [],
    continents: [],
    countries: [],
    categories: [],
    authors: [],
    biodiversities: [],
    users: [],
    imgfs: [],
    sections: [],
    lessons: [],
    slides: [],
    hspcategories: [],
    hotspots: [],
};

let intv;

const actions = {
    login: ({commit}, {email, password}) => {
        return api.login(email, password);
    },
    async refreshToken({commit}) {
        log.d("refreshToken()")
        try {
            let reply = await api.heartbeat(state.token)
            commit('token', {token: reply.data.token})
            commit('profile', {profile: reply.data.user})
        } catch (err) {
            commit('logout')
        }
        clearInterval(intv);
        intv = setInterval(async () => {
            try {
                let reply = await api.heartbeat(state.token)
                commit('token', {token: reply.data.token})
                commit('profile', {profile: reply.data.user})
            } catch (err) {
                commit('logout')
            }
        }, 1800000); // ogni 30 minuti
    },
    logout: ({commit}) => {
        commit('logout')
    },
    setToken: ({commit}, {token}) => {
        commit('token', {token})
    },
    setProfile: ({commit}, {profile}) => {
        commit('profile', {profile})
    },
    sendSuccess({commit}, {message}) {
        commit('message', {type: 'success', message})
    },
    sendWarning({commit}, {message}) {
        commit('message', {type: 'warning', message})
    },
    sendError({commit}, {message}) {
        commit('message', {type: 'error', message})
    },
    sendInfo({commit}, {message}) {
        commit('message', {type: 'info', message})
    },
    requestConfirm({commit}, {title, message, callback}) {
        commit('confirm', {title, message, callback})
    },
    load({commit}, {collection, filter}) {
        let {query, limit, page, sort, order} = filter;
        return api.restLoad(state.token, collection, query, limit, page, sort, order);
    },
    get({commit}, {collection, id}) {
        return api.restGet(state.token, collection, id);
    },
    insert({commit}, {collection, data}) {
        return api.restPost(state.token, collection, data);
    },
    update({commit}, {collection, data}) {
        return api.restPut(state.token, collection, data);
    },
    delete({commit}, {collection, id}) {
        return api.restDelete(state.token, collection, id);
    },
    upload({commit}, {where, formData}) {
        return api.restUpload(state.token, where, formData);
    },
    refreshCollection({commit}, {collection, sort}) {
        sort = sort ? encodeURIComponent(sort) : ""
        api.restLoad(state.token, collection, {}, 0, 1, sort, "").then((reply) => {
            if (reply && reply.data) {
                commit(collection, {data: reply.data});
            }
        }).catch(log.d);
    },
    async refreshCollectionAsync({commit}, {collection}) {
        try {
            let {data} = await api.restLoad(state.token, collection, {}, 0, 1, "", "");
            if (data) commit(collection, {data});
        } catch (e) {
            log.d(e)
            return false
        }
        return true
    },
};
const getters = {
    token: () => state.token,
    profile: () => state.profile,
    confirmDialogMessage: () => state.confirmDialogMessage,
    is_editor: () => state.profile && state.profile.role === 'editor',
    is_admin: () => state.profile && state.profile.role === 'admin',
    is_root: () => state.profile && state.profile.role === 'root',
    media: () => state.media,
    photos: () => state.photos,
    authors: () => state.authors,
    imgfs: () => state.imgfs,
    continents: () => state.continents,
    countries: () => state.countries,
    categories: () => state.categories,
    biodiversities: () => state.biodiversities,
    users: () => state.users,
    sections: () => state.sections,
    lessons: () => state.lessons,
    slides: () => state.slides,
    hspcategories: () => state.hspcategories,
    hotspots: () => state.hotspots,
};
const mutations = {
    logout: (state) => {
        state.token = null;
        state.profile = null;
        sessionStorage.removeItem('token');
        if (router.currentRoute.name !== 'login') router.replace('/login')
    },
    token: (state, {token}) => {
        state.token = token;
        if (token)
            sessionStorage.setItem("token", token);
        else
            sessionStorage.removeItem("token");
    },
    profile: (state, {profile}) => {
        state.profile = profile;
    },
    message: (state, {type, message}) => {
        switch (type) {
            case 'success':
                state.successDialog = true;
                state.dialogMessage = message;
                break;
            case 'warning':
                state.warningDialog = true;
                state.dialogMessage = message;
                break;
            case 'error':
                state.errorDialog = true;
                state.dialogMessage = message;
                break;
            case 'info':
            default:
                state.infoDialog = true;
                state.dialogMessage = message;
                break;
        }
    },
    confirm: (state, {title, message, callback}) => {
        state.confirmDialogTitle = title || 'Richiesta conferma';
        state.confirmDialogMessage = message || 'Sei sicuro?';
        state.confirmDialogCallback = callback || null;
        state.confirmDialog = true;
    },
    photos: (state, {data}) => {
        state.photos = data ? data : [];
    },
    continents: (state, {data}) => {
        state.continents = data ? data : [];
    },
    countries: (state, {data}) => {
        state.countries = data ? data : [];
    },
    sections: (state, {data}) => {
        state.sections = data ? data : [];
    },
    categories: (state, {data}) => {
        state.categories = data ? data : [];
    },
    biodiversities: (state, {data}) => {
        state.biodiversities = data ? data : [];
    },
    authors: (state, {data}) => {
        state.authors = data ? data : [];
    },
    users: (state, {data}) => {
        state.users = data ? data : [];
    },
    imgfs: (state, {data}) => {
        state.imgfs = data;
    },
    media: (state, {data}) => {
        state.media = data;
    },
    lessons: (state, {data}) => {
        state.lessons = data;
    },
    slides: (state, {data}) => {
        state.slides = data;
    },
    hspcategories: (state, {data}) => {
        state.hspcategories = data;
    },
    hotspots: (state, {data}) => {
        state.hotspots = data;
    },
};

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    strict: false,
    plugins: []
})