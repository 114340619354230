var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('h2',{staticClass:"display-1 ml-4"},[_vm._v("Categories")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary--text",attrs:{"color":"accent"},on:{"click":function($event){return _vm.$router.push('/categories/new')}}},[_vm._v("New")])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","hide-details":"","label":"Search by name"},on:{"input":_vm.applyFilter},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1)],1),_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.count,"loading":_vm.tableLoading,"no-data-text":"There are no items yet","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name.en",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'./#/categories/'+item._id}},[_c('b',[_vm._v(_vm._s(item.name ? item.name.en : '-'))])])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [(item.icon)?_c('a',{staticClass:"ma-0",attrs:{"href":'./#/categories/'+item._id}},[_c('v-img',{staticClass:"black",attrs:{"src":item.icon,"contain":"","width":"60","height":"60"}})],1):_vm._e()]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"accent","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.$router.push({ name : 'category form', params : { id : item._id }})}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.requestDelete(item._id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }