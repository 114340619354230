var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('h2',{staticClass:"display-1 ml-4"},[_vm._v("Survey results")])]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","hide-details":"","label":"Date from","type":"date","clearable":""},on:{"input":_vm.applyFilter},model:{value:(_vm.fromSearch),callback:function ($$v) {_vm.fromSearch=$$v},expression:"fromSearch"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","hide-details":"","label":"Date to","type":"date","clearable":""},on:{"input":_vm.applyFilter},model:{value:(_vm.toSearch),callback:function ($$v) {_vm.toSearch=$$v},expression:"toSearch"}})],1)],1),_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.count,"loading":_vm.tableLoading,"no-data-text":"There are no items yet","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){return _vm.showDetails(item)}}},[_c('b',[_vm._v(_vm._s(_vm.formatDate(item.date)))])])]}},{key:"item.choices",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.choices),function(choice){return _c('li',{key:_vm.item_id + '_' + choice._id},[_vm._v(_vm._s(choice.text.en))])}),0)]}},{key:"item.l1choices",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.l1choices),function(choice){return _c('li',{key:_vm.item_id + '_l1_' + choice._id},[_vm._v(_vm._s(choice.order + 1)+" - "+_vm._s(_vm.getSlideTitle(choice.slide_id))+" ")])}),0)]}},{key:"item.l2choices",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.l2choices),function(choice){return _c('li',{key:_vm.item_id + '_l2_' + choice._id},[_vm._v(_vm._s(choice.order + 1)+" - "+_vm._s(_vm.getSlideTitle(choice.slide_id))+" ")])}),0)]}},{key:"item.l3choices",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.l3choices),function(choice){return _c('li',{key:_vm.item_id + '_l3_' + choice._id},[_vm._v(_vm._s(choice.order + 1)+" - "+_vm._s(_vm.getSlideTitle(choice.slide_id))+" ")])}),0)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.requestDelete(item._id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }