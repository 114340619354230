<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <v-col cols="12" lg="8">
                    <v-text-field outlined dense label="Name*" v-model="editingItem.name.en"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-text-field outlined dense label="Code" v-model="editingItem.code"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-text-field outlined dense label="Color" v-model="editingItem.color"
                                  hint="Put here a valid hex color. ex: #00FF66" persistent-hint></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import log from '../log';
import {mapActions} from 'vuex';
import rules from "../utils/rules";

export default {
  data() {
    return {
      loading: false,
      editingItem: {
        name: {en: ''},
        code: '',
        color: '',
      },
      rules: {
        ...rules,
      }
    }
  },
  computed: {
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New bioregion' : 'Edit bioregion';
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert']),
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'biodiversities', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    async save(afterAction) {
      let updating = this.updating;
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      let saveFn = updating ? this.update : this.insert;

      try {
        let {data} = await saveFn({collection: 'biodiversities', data: this.editingItem});
        this.sendSuccess({message: "Data saved!"});
        this.loading = false;
        switch (afterAction) {
          case 1:
            await this.$router.replace('/bioregions')
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/bioregions/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              name: {en: ''},
              code: '',
              color: '',
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/bioregions/new`);
            }
            break
        }

      } catch (err) {
        this.loading = false;
        log.d("Save country error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>
