<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Latitude*" type="number" :step="0.00000001" v-model="latitude"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Longitude*" type="number" :step="0.00000001" v-model="longitude"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-autocomplete outlined dense label="Photo" clearable :items="photoOptions"
                                    v-model="editingItem.photo_id"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-autocomplete outlined dense label="Media" clearable :items="mediaOptions"
                                    v-model="editingItem.media_id"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-switch class="min-content" label="Published" v-model="editingItem.pub"></v-switch>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import utils from '../utils';
import {mapGetters, mapActions} from 'vuex';
import log from "../log";
import rules from "../utils/rules";

export default {
  data() {
    return {
      loading: false,
      longitude: 0,
      latitude: 0,
      editingItem: {
        location: {type: 'Point', coordinates: [0, 0]},
        photo_id: null,
        media_id: null,
        pub: false
      },
      rules: {
        ...rules,
      }
    }
  },
  computed: {
    ...mapGetters(['media', 'photos']),
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New poi' : 'Edit poi';
    },
    photoOptions() {
      return this.photos.map((item) => {
        return {text: item.title.en || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
    mediaOptions() {
      return this.media.map((item) => {
        return {text: item.name || '-', value: item._id}
      }).sort(utils.sortTextOn('text'))
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert', 'refreshCollection']),
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'poi', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.editingItem = reply.data;
          this.longitude = reply.data.location.coordinates[0];
          this.latitude = reply.data.location.coordinates[1];
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.$router.go(-1)
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },

    async save(afterAction) {
      let updating = this.updating;
      if (!this.$refs.form.validate()) return;
      this.editingItem.location = {
        type: 'Point',
        coordinates: [utils.toFloat(this.longitude, 0), utils.toFloat(this.latitude, 0)]
      }
      this.loading = true;
      let saveFn = updating ? this.update : this.insert;
      try {
        let {data} = await saveFn({collection: 'poi', data: this.editingItem})
        this.sendSuccess({message: "Data saved!"});
        this.loading = false;
        switch (afterAction) {
          case 1:
            await this.$router.replace('/poi');
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/poi/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              location: {type: 'Point', coordinates: [0, 0]},
              photo_id: null,
              media_id: null,
              pub: false
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/poi/new`);
            }
            break
        }
      } catch (err) {
        this.loading = false;
        log.d("Save poi error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/poi");
          this.logout();
        }
      }

    }
  },
  async mounted() {
    if (this.$route.params.id !== 'new') {
      this.loadData()
    }

    this.refreshCollection({collection: 'photos'});
    this.refreshCollection({collection: 'media'});
  }
}
</script>
