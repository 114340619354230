<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(4)" :loading="loading">Save and add
              slide
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <v-col cols="12">
                    <v-text-field outlined dense label="Title*" v-model="editingItem.title.en"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field outlined dense label="Subtitle*" v-model="editingItem.subtitle.en"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea outlined dense label="Description*" v-model="editingItem.desc.en"
                                :rules="[rules.required]"></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea outlined dense label="Question" v-model="editingItem.question.en"></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="Higher question value"
                                  v-model="editingItem.sel_higher.en"
                                  hint="Label for item selection order, es. 'most dangerous'"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="Lower question value"
                                  v-model="editingItem.sel_lower.en"
                                  hint="Label for item selection order, es. 'less dangerous'"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="Empathy"
                                  v-model="editingItem.empathy.en"
                                  hint="Label for the target of empathy in the lesson (es. 'the photographers', 'the animals', ecc.)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="Feedback Title"
                                  v-model="editingItem.feedback_title.en"
                                  hint="Feedback title, appear on the feedback dialog at the end of questionary"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="Cover url" v-model="editingItem.cover"
                                  hint="fill either this field with a valid url or the file input to set the cover icon"
                                  persistent-hint></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-file-input outlined dense accept=".png" label="Cover file" v-model="file"
                                  hint="fill either this file input or the url field with a valid url to set the cover icon"
                                  persistent-hint></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete outlined dense chips deletable-chips label="Slides" v-model="editingItem.slides_ids"
                                    :items="slidesOptions" multiple clearable></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" lg="2" xl="1">
                    <v-text-field outlined dense label="Order*" v-model="editingItem.order"
                                  :rules="[rules.required, rules.minInt(1)]"></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" lg="2" xl="1">
                    <v-switch label="Published" v-model="editingItem.pub"></v-switch>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(4)" :loading="loading">Save and add
              slide
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import log from '../log';
import {mapActions, mapGetters} from 'vuex';
import rules from "../utils/rules";
import utils from "@/utils";

export default {
  data() {
    return {
      loading: false,
      file: null,
      editingItem: {
        title: {en: ''},
        subtitle: {en: ''},
        desc: {en: ''},
        question: {en: ''},
        sel_higher: {en: ''},
        sel_lower: {en: ''},
        empathy: {en: ''},
        feedback_title: {en: ''},
        cover: '',
        order: 1,
        slides_ids: [],
        pub: true,
      },
      rules: {
        ...rules,
      }
    }
  },
  computed: {
    ...mapGetters(['slides', 'lessons']),
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New lesson' : 'Edit lesson';
    },
    slidesOptions() {
      return this.slides.map(item => {
        return {text: item.title.en, value: item._id}
      })
    }

  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert', 'upload', 'refreshCollection', 'refreshCollectionAsync']),
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'lessons', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          if (!reply.data.question) reply.data.question = {en: ''};
          if (!reply.data.sel_higher) reply.data.sel_higher = {en: ''};
          if (!reply.data.sel_lower) reply.data.sel_lower = {en: ''};
          if (!reply.data.empathy) reply.data.empathy = {en: ''};
          if (!reply.data.feedback_title) reply.data.feedback_title = {en: ''};
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    async save(afterAction) {
      let updating = this.updating;
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      let saveFn = updating ? this.update : this.insert;
      this.editingItem.order = utils.toInt(this.editingItem.order, 1)
      try {
        let {data} = await saveFn({collection: 'lessons', data: this.editingItem});

        if (this.file) {
          let formData = new FormData();
          formData.append("file", this.file);
          let reply = await this.upload({where: `lessons/${data._id}/cover`, formData})
          data = reply.data;
          this.file = null;
        }
        this.loading = false;
        this.sendSuccess({message: "Data saved!"});
        switch (afterAction) {
          case 1:
            await this.$router.replace('/lessons')
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/lessons/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              title: {en: ''},
              subtitle: {en: ''},
              desc: {en: ''},
              question: {en: ''},
              sel_higher: {en: ''},
              sel_lower: {en: ''},
              empathy: {en: ''},
              feedback_title: {en: ''},
              cover: '',
              order: 1,
              slides_ids: [],
              pub: true,
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/lessons/new`);
            }
            break
          case 4:
            await this.$router.replace('/lessons/' + data._id + "/slides/new")
            break
        }

      } catch (err) {
        this.loading = false;
        log.d("Save lesson error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    this.refreshCollection({collection: 'slides'});
    if (this.$route.params.id !== 'new') {
      this.loadData()
    } else {
      this.refreshCollectionAsync({collection: 'lessons'}).then(() => {
        this.editingItem.order = this.lessons.length + 1;
      })
    }
  }
}
</script>
