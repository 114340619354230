<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <v-col cols="12">
                    <v-text-field outlined dense label="Name*" v-model="editingItem.name.en"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense label="Mini map url" v-model="editingItem.mini_map"
                                  hint="fill either this field with a valid url or the file input to set the minimap icon"
                                  persistent-hint></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-file-input outlined dense accept=".png" label="Mini map file" v-model="file"
                                  hint="fill either this file input or the url field with a valid url to set the minimap icon"
                                  persistent-hint></v-file-input>
                  </v-col>

                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Order" v-model="editingItem.order" type="number"
                                  :step="1"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Center longitude*" v-model="longitude" type="number"
                                  :step="0.00000001"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Center latitude*" v-model="latitude" type="number"
                                  :step="0.00000001"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-text-field outlined dense label="Zoom*" v-model="editingItem.zoom"
                                  :rules="[rules.required, rules.rangeInt(2,10)]" type="number"
                                  :step="1"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import log from '../log';
import {mapActions} from 'vuex';
import rules from "../utils/rules";
import utils from "@/utils";

export default {
  data() {
    return {
      loading: false,
      file: null,
      longitude: 0,
      latitude: 0,
      editingItem: {
        name: {en: ''},
        mini_map: '',
        center: {type: 'Point', coordinates: [0, 0]},
        zoom: 2,
        order: 0,
      },
      rules: {
        ...rules,
      }
    }
  },
  computed: {
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New continent' : 'Edit continent';
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert', 'upload']),
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'continents', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.editingItem = reply.data;
        }
        if (!this.editingItem.center || !this.editingItem.center.coordinates) {
          this.editingItem.center = {type: 'Point', coordinates: [0, 0]};
        }
        if (!this.editingItem.zoom) {
          this.editingItem.zoom = 2;
        }

        if (reply.data.center && reply.data.center.coordinates) {
          this.longitude = reply.data.center.coordinates[0];
          this.latitude = reply.data.center.coordinates[1];
        }

      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    async save(afterAction) {
      let updating = this.updating;
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      let saveFn = updating ? this.update : this.insert;
      this.editingItem.center = {
        type: 'Point',
        coordinates: [utils.toFloat(this.longitude, 0), utils.toFloat(this.latitude, 0)]
      }
      this.editingItem.zoom = utils.toInt(this.editingItem.zoom, 2);
      this.editingItem.order = utils.toInt(this.editingItem.order, 0);
      try {
        let {data} = await saveFn({collection: 'continents', data: this.editingItem});

        if (this.file) {
          let formData = new FormData();
          formData.append("file", this.file);
          let reply = await this.upload({where: `continents/${data._id}/upload`, formData})
          data = reply.data;
          this.file = null;
        }
        this.loading = false;
        this.sendSuccess({message: "Data saved!"});
        switch (afterAction) {
          case 1:
            await this.$router.replace('/continents')
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/continents/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              name: {en: ''},
              mini_map: '',
              center: {type: 'Point', coordinates: [0, 0]},
              zoom: 2,
              order: 0,
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/continents/new`);
            }
            break
        }

      } catch (err) {
        this.loading = false;
        log.d("Save continent error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>
