var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('h2',{staticClass:"display-1 ml-4"},[_vm._v("Poi")]),_c('v-spacer'),_c('v-btn',{staticClass:"secondary--text",attrs:{"color":"accent"},on:{"click":function($event){return _vm.$router.push('/poi/new')}}},[_vm._v("New")])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-autocomplete',{attrs:{"filled":"","rounded":"","dense":"","hide-details":"","clearable":"","items":_vm.photoOptions,"label":"Search by photo"},on:{"change":_vm.applyFilter},model:{value:(_vm.photoSearch),callback:function ($$v) {_vm.photoSearch=$$v},expression:"photoSearch"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-autocomplete',{attrs:{"filled":"","rounded":"","dense":"","hide-details":"","clearable":"","items":_vm.mediaOptions,"label":"Search by media"},on:{"change":_vm.applyFilter},model:{value:(_vm.mediaSearch),callback:function ($$v) {_vm.mediaSearch=$$v},expression:"mediaSearch"}})],1)],1),_c('v-row',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.count,"loading":_vm.tableLoading,"no-data-text":"There are no items yet","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.media.name",fn:function(ref){
var item = ref.item;
return [(item.media)?_c('a',{attrs:{"href":'./#/media/'+item.media._id,"title":item.media.name}},[_c('b',[_vm._v(_vm._s(item.media.name))])]):_vm._e()]}},{key:"item.photo.title.en",fn:function(ref){
var item = ref.item;
return [(item.photo)?_c('a',{attrs:{"href":'./#/photos/'+item.photo._id,"title":item.photo.title.en}},[_c('b',[_vm._v(_vm._s(item.photo.title.en))])]):_vm._e()]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(" lat: "+_vm._s(item.location.coordinates[1])+", lng: "+_vm._s(item.location.coordinates[0])+" ")]}},{key:"item.pub",fn:function(ref){
var item = ref.item;
return [(item.pub)?_c('strong',{staticClass:"success--text"},[_vm._v("Yes")]):_c('strong',{staticClass:"error--text"},[_vm._v("No")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.$router.push({ name : 'poi form', params : { id : item._id }})}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.requestDelete(item._id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }