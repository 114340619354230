<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Tools</h2>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row row wrap>
                <v-col cols="12"><h2 class="subheading">Import xlsx</h2></v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-file-input outlined dense v-model="file" label="File to import" />
                  <v-btn :disabled="!file" color="accent" @click="importXlsx()" :loading="loading">Import xlsx</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import config from "../config";
import {mapActions, mapGetters} from "vuex";
import _ from 'lodash'

export default {
  name: "Tools",
  data () {
    return {
      file : null,
      loading: false,
    };
  },
  computed : {
    ...mapGetters(["token"]),
   
  },
  methods : {
    ...mapActions(['get','sendError','sendSuccess','requestConfirm','upload',"update"]),
    async importXlsx() {
      if(!this.file) return;
      const where = "import"
      const formData = new FormData()
      formData.append("file", this.file)
      this.loading = true;
      try {
        await this.upload({ where, formData})
        this.sendSuccess({message:"Import running!"})
      } catch (e) {
        this.sendError({message: "Error while importing file"})
        console.log(e)
      }
      this.loading = false;
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
