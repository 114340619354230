<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Survey contents</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save()" :loading="loading">Save</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <wysiwyg label="Survey intro" v-model="editingItem.intro_text.en"
                           hint="this text before the choices"
                           persistent-hint></wysiwyg>
                </v-col>
                <v-col cols="12">
                  <wysiwyg label="Survey outro" v-model="editingItem.outro_text.en"
                           hint="this text is shown choices sending"
                           persistent-hint></wysiwyg>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"><h2 class="subheading">Survey choices</h2></v-col>
                <v-col cols="12">
                  <v-text-field outlined dense label="Add a choice" v-model="newChoice" hide-details
                                append-outer-icon="mdi-plus" @click:append-outer="addChoice()"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-list>
                    <v-list-item v-for="choice in choices" :key="choice._id" style="border-bottom: 1px solid black"
                                 link>
                      <v-list-item-title>{{ choice.text.en }}</v-list-item-title>
                      <v-list-item-action>
                        <v-btn icon @click="editChoice(choice)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon @click="removeChoice(choice._id)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save()" :loading="loading">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


    <v-dialog v-model="editChoiceDialog">
      <v-card>
        <v-card-text>
          <v-text-field dense outlined label="Choice text" v-model="editingChoice.text.en"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="editChoiceDialog = false">Cancel</v-btn>
          <v-btn color="accent" text @click="updateChoice()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import config from "../config";
import {mapActions, mapGetters} from "vuex";
import _ from 'lodash'
import Wysiwyg from "@/components/Wysiwyg";
import log from "@/log";

export default {
  name: "Survey",
  components: {
    Wysiwyg
  },
  data() {
    return {
      editChoiceDialog: false,
      editingChoice: {
        text: {en: ''}
      },
      editingItem: {
        intro_text: {en: ''},
        outro_text: {en: ''},
        choices_ids: [],
      },
      choices: [],
      newChoice: '',
      file: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    ...mapActions(['get', 'load', 'sendError', 'sendSuccess', 'requestConfirm', 'upload', "update", "insert", "delete", 'logout']),
    async addChoice() {
      if (!this.newChoice) {
        this.sendError({message: 'Add a choice field must not be empty'})
        return;
      }
      await this.insert({collection: 'survey/choices', data: {text: {en: this.newChoice}}})
      await this.loadChoices();
    },
    async editChoice(data) {
      this.editingChoice = data;
      this.editChoiceDialog = true;
    },
    async updateChoice() {
      if (!this.editingChoice._id) return;
      await this.update({collection: 'survey/choices', data: this.editingChoice})
      await this.loadChoices();
    },
    removeChoice(id) {
      this.requestConfirm({
        title: 'Delete request', message: 'Are you sure to permanently delete this choice?', callback: (accepted) => {
          if (accepted) {
            this.delete({collection: 'survey/choices', id}).then(() => {
              this.loadChoices().then(() => {
              });
              this.sendSuccess({message: 'Choice deleted!'});
            }).catch((err) => {
              let message = err.message ? err.message : "there was an error and the request could not be completed";
              this.sendError({message});
              log.d("there was an error while removing section", err);
              if (err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }
      });
    },
    async save() {
      this.loading = true;
      this.editingItem.choices_ids = this.choices.map(item => item._id);
      try {
        let reply = await this.insert({collection: 'survey', data: this.editingItem})
        this.editingItem = reply.data;
        this.sendSuccess({message: "Data saved!"});
      } catch (err) {
        let message = err.message ? err.message : "there was an error and the request could not be completed";
        this.sendError({message});
        log.d("there was an error while removing section", err);
        if (err.statusCode === 401) {
          this.logout();
        }
      }
      this.loading = false;
    },
    async loadData() {
      try {
        let {data} = await this.get({collection: 'survey'})
        this.editingItem = data;
      } catch (e) {
        this.sendError({message: "Error loading survey data"})
        console.log(e)
      }
    },
    async loadChoices() {
      try {
        let {data} = await this.load({
          collection: 'survey/choices',
          filter: {query: {}, limit: 0, page: 1, sort: '+name.en'}
        })
        this.choices = data;
      } catch (e) {
        this.sendError({message: "Error loading survey data"})
        console.log(e)
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.loadData();
    await this.loadChoices();
    this.loading = false;
  }
}
</script>

<style scoped>

</style>
