<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row row wrap>

                  <v-col cols="12" lg="2">
                    <v-switch outlined dense label="External" v-model="editingItem.external"></v-switch>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-select outlined dense label="Type" v-model="editingItem.type" :rules="[rules.required]"
                              :items="mediaTypes"></v-select>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-text-field outlined dense label="Title*" v-model="editingItem.title.en"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-text-field outlined dense label="Subtitle*" v-model="editingItem.subtitle.en"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-textarea outlined dense label="Description 1*" v-model="editingItem.desc_1.en"></v-textarea>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-textarea outlined dense label="Description 2*" v-model="editingItem.desc_2.en"></v-textarea>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field outlined dense label="Cover Url" v-model="editingItem.cover_url"
                                  :disabled="!!coverFile"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-file-input ref="file" outlined dense label="Cover File"
                                  v-model="coverFile"></v-file-input>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field outlined dense label="Url" v-model="editingItem.url"
                                  :disabled="!!file"></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-file-input :disabled="editingItem.external" ref="file" outlined dense label="File"
                                  v-model="file"></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import utils from '../utils';
import {mapGetters, mapActions} from 'vuex';
import log from "../log";
import rules from "@/utils/rules";

export default {
  data() {
    return {
      loading: false,
      file: null,
      coverFile: null,
      editingItem: {
        title: {en: ''},
        subtitle: {en: ''},
        desc_1: {en: ''},
        desc_2: {en: ''},
        cover_url: '',
        url: '',
        external: false,
        type: ''
      },
      rules: {
        ...rules,
      },
      mediaTypes: [
        {text: 'audio', value: 'audio'},
        {text: 'image', value: 'image'},
        {text: 'video', value: 'video'},
        {text: 'vimeo', value: 'vimeo'},
        {text: 'youtube', value: 'youtube'},
      ]
    }
  },
  computed: {
    ...mapGetters(['is_admin', 'photos']),
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New media' : 'Edit media';
    },
  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'upload', 'insert', 'refreshCollection']),
    conversionStatusClass(status) {
      switch (status) {
        case 'fail':
          return 'error--text';
        case 'done':
          return 'success--text';
        case 'running':
          return 'warning--text';
        case 'waiting':
          return 'info--text';
      }
      return ''
    },
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'media', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },

    async onSuccess(data, afterAction) {
      this.loading = false;
      this.sendSuccess({message: "Data saved!"});
      switch (afterAction) {
        case 1:
          await this.$router.replace('/media')
          break
        case 2:
          this.editingItem = data;
          if (this.$route.params.id === 'new') {
            await this.$router.replace(`/media/${data._id}`);
          }
          break
        case 3:
          this.editingItem = {
            title: {en: ''},
            subtitle: {en: ''},
            desc_1: {en: ''},
            desc_2: {en: ''},
            cover_url: '',
            url: '',
            type: '',
            external: false
          }
          if (this.$route.params.id !== 'new') {
            await this.$router.replace(`/media/new`);
          }
          break
      }
    },

    async save(afterAction) {
      if (!this.$refs.form.validate()) return;
      let updating = this.updating;
      this.loading = true;
      try {
        let saveFn = updating ? this.update : this.insert;
        let reply = await saveFn({collection: 'media', data: this.editingItem});
        if (this.coverFile) {
          let formData = new FormData();
          formData.append("file", this.coverFile);
          let where = `media/${reply.data._id}/upload/cover`
          reply = await this.upload({where, formData});
        }

        if (!this.external && this.file) {

          let formData = new FormData();
          formData.append("file", this.file);
          let where = `media/${reply.data._id}/upload`
          reply = await this.upload({where, formData});

        }

        await this.onSuccess(reply.data, afterAction)

      } catch (err) {
        log.d("Save imgf error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    this.refreshCollection({collection: 'photos'})
    if (this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>
