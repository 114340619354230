<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(2)" :loading="loading">Save and
              continue
            </v-btn>
            <v-btn color="accent" class="ml-2 secondary--text" small @click="save(3)" :loading="loading">Save and add
              new
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <v-col cols="12">
                    <v-text-field outlined dense label="Title*" v-model="editingItem.title.en"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field outlined dense label="Subtitle*" v-model="editingItem.subtitle.en"
                                  :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea outlined dense label="Description*" v-model="editingItem.desc.en"
                                :rules="[rules.required]"></v-textarea>
                  </v-col>
                  <!--
                  <v-col cols="12">
                    <v-textarea outlined dense label="Reason for selection*" v-model="editingItem.sel_reason.en"
                                :rules="[rules.required]"></v-textarea>
                  </v-col>
                  -->
                  <v-col cols="12">
                    <wysiwyg label="Selection result*" v-model="editingItem.result.en"
                             :rules="[rules.required]"
                             hint="this text is shown as result if this slide is selected as first choice"
                             persistent-hint></wysiwyg>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete outlined dense label="Photo" v-model="editingItem.photo_id"
                                    :items="photosOptions" :rules="[rules.required]"></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="4" md="3" lg="2" xl="1">
                    <v-text-field outlined dense label="Order*" v-model="editingItem.order"
                                  :rules="[rules.required, rules.minInt(1)]"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save(1)" :loading="loading">Save</v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(2)" :loading="loading">Save and continue
            </v-btn>
            <v-btn color="accent" class="secondary--text" small @click="save(3)" :loading="loading">Save and add new
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import log from '../log';
import {mapActions, mapGetters} from 'vuex';
import rules from "../utils/rules";
import utils from "@/utils";
import Wysiwyg from "../components/Wysiwyg";

export default {
  components: {
    Wysiwyg
  },
  data() {
    return {
      loading: false,
      lesson: null,
      editingItem: {
        title: {en: ''},
        subtitle: {en: ''},
        desc: {en: ''},
        sel_reason: {en: ''},
        result: {en: ''},
        photo_id: null,
        order: 1,
      },
      rules: {
        ...rules,
      }
    }
  },
  computed: {
    ...mapGetters(['slides', 'photos']),
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'New lesson slide' : 'Edit lesson slide';
    },
    photosOptions() {
      return this.photos.map(item => {
        return {text: item.title.en, value: item._id}
      })
    }

  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert', 'upload', 'refreshCollection', 'refreshCollectionAsync']),
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'slides', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.editingItem = reply.data;
        }
        if (!this.editingItem.sel_reason) {
          this.editingItem.sel_reason = {en: ''};
        }
        if (!this.editingItem.result) {
          this.editingItem.result = {en: ''};
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    async save(afterAction) {
      let updating = this.updating;
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      let saveFn = updating ? this.update : this.insert;
      this.editingItem.order = utils.toInt(this.editingItem.order, 1)
      try {
        let {data} = await saveFn({collection: 'slides', data: this.editingItem});

        if (this.$route.params.id === 'new') {
          if (!this.lesson.slides_ids) this.lesson.slides_ids = [];
          this.lesson.slides_ids.push(data._id);
          let reply = await this.update({collection: 'lessons', data: this.lesson})
          this.lesson = reply.data;
        }


        this.loading = false;
        this.sendSuccess({message: "Data saved!"});
        switch (afterAction) {
          case 1:
            await this.$router.replace(`/lessons/${this.lesson._id}/slides`)
            break
          case 2:
            this.editingItem = data;
            if (this.$route.params.id === 'new') {
              await this.$router.replace(`/lessons/${this.lesson._id}/slides/${data._id}`);
            }
            break
          case 3:
            this.editingItem = {
              title: {en: ''},
              subtitle: {en: ''},
              desc: {en: ''},
              sel_reason: {en: ''},
              result: {en: ''},
              cover: '',
              order: 1,
              slides_ids: [],
              pub: true,
            }
            if (this.$route.params.id !== 'new') {
              await this.$router.replace(`/lessons/${this.lesson._id}/slides/new`);
            }
            break
        }

      } catch (err) {
        this.loading = false;
        log.d("Save lesson error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    this.refreshCollection({collection: 'photos'});

    this.loading = true;
    this.get({collection: 'lessons', id: this.$route.params.lesson_id}).then((reply) => {
      this.lesson = reply.data;
      if (!this.lesson) {
        this.$router.go(-1);
        return
      }
      if (this.$route.params.id !== 'new') {
        this.loadData()
      } else {
        this.loading = false;
        this.editingItem.order = this.lesson.slides_ids ? this.lesson.slides_ids.length + 1 : 1;
      }
    }).catch((err) => {
      log.d(err)
      this.$router.go(-1);
    });
  }
}
</script>
