<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Images importer</h2>
          </v-card-title>
          <v-card-text>
            <v-container>
              <vue-dropzone id="drop1" ref="dropzone" @vdropzone-sending="onSendingFormData" :options="dropOptions"></vue-dropzone>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save()" :loading="loading">Run import!</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import log from '../log'
export default {
  components: {
    vueDropzone
  },
  data () {
    return {
      loading: false,
      editingItem : {
        title : '',
        name : '',
        url : '',
        xlserver : '',
        source_url : '',
        source_exists : false,
        source_size : 0,
        imgf_exists : false,
        imgf_size : 0,
        conversion_status : '',
        import_error : '',
      },
      chunkSent : 0

    }
  },
  computed : {
    ...mapGetters(['token']),
    dropOptions() {
      return {
        url: config.server + "/api/imgfs/upload",
        maxFilesize: 24000, // MB
        maxFiles: 10,
        chunking: true,
        forceChunking : true,
        thumbnailWidth: 150, // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        autoProcessQueue : false,
        headers: { "x-token": 'Bearer ' + this.token },
        uploadMultiple: false,
        paramName : 'images',
      };
    },
    updating() {
      return !!this.editingItem._id
    },
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete','get', 'update', 'insert']),
    onSendingFormData(file, xhr, formData) {
     log.d(JSON.stringify(this.dropOptions))
      //formData.append('collection_id', this.editingItem.collection_id);
    },
    save() {
      this.$refs.dropzone.processQueue();
    }
  }
}
</script>
