<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Settings</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save()" :loading="loading">Save</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" lg="6">
                  <v-text-field dense outlined label="Hotspot Distance To Center Weight"
                                v-model="editingItem.hotspot_distance_to_center_weight"
                                type="number" :step="0.01"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field dense outlined label="Hotspot Covering Percent Weight"
                                v-model="editingItem.hotspot_covering_percent_weight"
                                type="number" :step="0.01"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field dense outlined label="Hotspot Area Weight" v-model="editingItem.hotspot_area_weight"
                                type="number" :step="0.01"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field dense outlined label="Hotspot Result Limit" v-model="editingItem.hotspot_result_limit"
                                type="number" :step="1"></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field dense outlined label="Hotspot Per Category Result Limit"
                                v-model="editingItem.hotspot_per_category_result_limit"
                                type="number" :step="1"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" small @click="save()" :loading="loading">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import log from "@/log";
import utils from "@/utils";

export default {
  name: "Settings",

  data() {
    return {

      editingItem: {
        hotspot_distance_to_center_weight: 1,
        hotspot_covering_percent_weight: 0.8,
        hotspot_area_weight: 0.2,
        hotspot_result_limit: 20,
        hotspot_per_category_result_limit: 10,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    ...mapActions(['get', 'load', 'sendError', 'sendSuccess', 'requestConfirm', 'upload', "update", "insert", "delete", 'logout']),
    async save() {
      this.loading = true;
      this.editingItem.hotspot_distance_to_center_weight = utils.toFloat(this.editingItem.hotspot_distance_to_center_weight, 1)
      this.editingItem.hotspot_covering_percent_weight = utils.toFloat(this.editingItem.hotspot_covering_percent_weight, 0.8)
      this.editingItem.hotspot_area_weight = utils.toFloat(this.editingItem.hotspot_area_weight, 0.2)
      this.editingItem.hotspot_result_limit = utils.toInt(this.editingItem.hotspot_result_limit, 20)
      this.editingItem.hotspot_per_category_result_limit = utils.toInt(this.editingItem.hotspot_per_category_result_limit, 10)
      try {
        let reply = await this.insert({collection: 'settings', data: this.editingItem})
        this.editingItem = reply.data;
        this.sendSuccess({message: "Data saved!"});
      } catch (err) {
        let message = err.message ? err.message : "there was an error and the request could not be completed";
        this.sendError({message});
        log.d("there was an error while removing section", err);
        if (err.statusCode === 401) {
          this.logout();
        }
      }
      this.loading = false;
    },
    async loadData() {
      try {
        let {data} = await this.get({collection: 'settings'})
        this.editingItem = data;
      } catch (e) {
        this.sendError({message: "Error loading settings data"})
        console.log(e)
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.loadData();
    this.loading = false;
  }
}
</script>

<style scoped>

</style>
