import _ from "lodash";
import moment from 'moment'

moment.locale('en')
import config from '../config'

const getChildrenOf = (ctg, map) => {
    if (map[ctg._id]) {
        let children = map[ctg._id];
        for (let i = 0; i < children.length; i++) {
            let item = children[i];
            children[i] = getChildrenOf(item, map);
        }
        ctg.children = children;
    }
    return ctg;
};


const utils = {
    zeroPad: (number, width) => {
        width -= number.toString().length;
        if (width > 0) {
            return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
        }
        return number + ""; // always return a string
    },
    checkSessionError(err) {
        if (err.status === 401) {
            const message = err && err.message ? err.message : 'invalid or expired session'
            this.sendError({message});
            this.logout();
            return false
        }
        return true
    },
    isValidPassword(password) {
        return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password)
    },
    isValidEmail(mail) {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(mail)
    },
    isValidColor(hex) {
        return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hex)
    },
    isValidUrl(url) {
        return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i.test(url)
    },
    isValidPhone(value) {
        return /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/i.test(value)
    },
    toFloat(val, defaultVal) {
        if (_.isNil(val)) return defaultVal;
        if (_.isNumber(val)) return val;
        if (_.isString(val)) {
            let num = parseFloat(val.replace(",", "."));
            if (!_.isNaN(num)) {
                return num;
            }
        }
        return defaultVal;
    },
    toInt(val, defaultVal) {
        if (_.isNil(val)) return defaultVal;
        if (_.isNumber(val)) return val;
        if (_.isString(val)) {
            let num = parseInt(val.replace(",", "."), 10);
            if (!_.isNaN(num)) {
                return num;
            }
        }
        return defaultVal;
    },
    splitUrl(url) {
        if (!url) return {path: null, name: null};
        let spl = url.split("/");
        if (spl.length === 1) return {path: '/', name: spl[0]};
        let name = spl.pop();
        let path = spl.join("/") + "/";
        return {path, name};
    },
    xlimageThumb(imgf, w, h, q) {
        if (!q) {
            q = 70;
        }
        return imgf + "?cmd=whole&w=" + w + "&h=" + h + "&q=" + q;
    },
    xlimageThumbHotspotURL(imgf, hsp, w, h, q) {
        if (!q) {
            q = 70;
        }
        let spl = imgf.split("closer");

        if (spl.length !== 2) return utils.xlimageThumb(imgf, w, h, q);

        let url = config.server + "/xlimage/hotspot" + spl[1];
        url += "?x=" + hsp.x + "&y=" + hsp.y + "&w=" + hsp.w + "&h=" + hsp.h + "&tw=" + w + "&th=" + h;
        return url;
    },
    stringSearch(value, test) {
        return value.toLowerCase().indexOf(test.toLowerCase()) !== -1;
    },
    stringInChild(categories, parentId, lng, test) {
        for (let i = 0; i < categories.length; i++) {
            let ctg = categories[i];
            if (ctg.parent_id === parentId) {
                if (utils.stringSearch(ctg.name[lng], test) || utils.stringInChild(categories, ctg._id, lng, test)) {
                    return true
                }
            }
        }
        return false;
    },
    sortTextOn(prop) {
        return (a, b) => {
            let textA = a[prop].toLowerCase();
            let textB = b[prop].toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }
    },
    sortFunction(property, direction) {
        if (!direction) direction = "asc";
        const one = direction === "asc" ? 1 : -1;
        return (a, b) => {
            if (a[property] > b[property]) {
                return one;
            }
            if (a[property] < b[property]) {
                return -one;
            }
            return 0;
        }
    },
    valueInRange(value, min, max) {
        return (value <= max) && (value >= min);
    },
    areaOverlap(A, B) {
        const xOverlap = utils.valueInRange(A.x, B.x, B.x + B.width) || //utils.valueInRange(val:A.x, min:B.x, maxB.x + B.width)
            utils.valueInRange(B.x, A.x, A.x + A.width);
        const yOverlap = utils.valueInRange(A.y, B.y, B.y + B.height) ||
            utils.valueInRange(B.y, A.y, A.y + A.height);
        return xOverlap && yOverlap;
    },
    getRectCenter(rect) {
        return {
            x: rect.x + rect.width / 2,
            y: rect.y + rect.height / 2
        }
    },
    calculateDistanceBetweenTwoPoints(pointA, pointB) {
        const xq = Math.pow(pointB.x - pointA.x, 2);
        const yq = Math.pow(pointB.y - pointA.y, 2);
        return Math.sqrt(xq + yq);
    },
    capText(text, maxChars) {
        if (text.length > maxChars) {
            return text.substr(0, maxChars - 3) + "..."
        }
        return text
    },
    copy: (obj) => {
        return JSON.parse(JSON.stringify(obj));
    },
    formatDate(date) {
        if (!date) return '-';
        return moment(date).format('L')
    },
    formatDateTime(date) {
        if (!date) return '-';
        return moment(date).format('L LT')
    },
    bind() {
        if (arguments.length < 2) return;
        const _this = arguments[0];
        for (let i = 1; i < arguments.length; i++) {
            let fn = arguments[i];
            _this[fn] = _this[fn].bind(_this);
        }
    },
    getInnerHeight(domEl) {
        const computed = getComputedStyle(domEl);
        const paddingH = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);
        return domEl.clientHeight - paddingH;
    },
    getMouseCoordinates(e, target) {
        const rect = target.getBoundingClientRect();
        const x = e.clientX - Math.round(rect.left); //x position within the element.
        const y = e.clientY - Math.round(rect.top);  //y position within the element.
        return {x, y}
    },
    getFileExt(url) {
        return url.split('.').pop();
    },
    extractPagination(pagination, query) {
        const {sortBy, sortDesc, page, itemsPerPage} = pagination;
        let sort = null;
        let order = null;
        if (sortBy.length) {
            sort = sortBy[0];
            order = 'ASC';
            if (sortDesc[0]) order = 'DESC';
        }
        return {query, limit: itemsPerPage, page, sort, order};
    },
    getObjectInArray(items, byKey, value) {
        if (!items) return null;
        let filter = items.filter(item => item[byKey] === value)
        return filter.length ? filter[0] : null;
    },
    roleVal(role) {
        switch (role) {
            case 'root':
                return 10000;
            case 'admin':
                return 9000;
            case 'editor':
                return 5000;
            default:
                return 0;
        }
    },
    hspcategoriesToTree(ctgs) {
        let tree = [];
        let map = {};
        for (let i = 0; i < ctgs.length; i++) {

            let ctg = JSON.parse(JSON.stringify(ctgs[i]));
            if (!ctg.parent_id) {
                tree.push(ctg)
            } else {
                if (!map[ctg.parent_id]) map[ctg.parent_id] = [];
                map[ctg.parent_id].push(ctg)
            }
        }
        for (let i = 0; i < tree.length; i++) {
            let item = tree[i];
            tree[i] = getChildrenOf(item, map)
        }
        return tree
    },
};
export default utils;