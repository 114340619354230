<template>
  <div>
    <div class="art-wysiwyg">
      <div class="d-flex" style="position: relative">
        <label class="v-label theme--light ma-2">{{ label }}</label>
        <v-menu>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-btn class="ma-1" color="accent" small icon v-bind="attrs" v-on="{ ...menu }">
              <v-icon v-if="currentTextFormat==='p'">mdi-format-paragraph</v-icon>
              <v-icon v-if="currentTextFormat==='h1'">mdi-format-header-1</v-icon>
              <v-icon v-if="currentTextFormat==='h2'">mdi-format-header-2</v-icon>
              <v-icon v-if="currentTextFormat==='h3'">mdi-format-header-3</v-icon>
              <v-icon v-if="currentTextFormat==='h4'">mdi-format-header-4</v-icon>
              <v-icon v-if="currentTextFormat==='h5'">mdi-format-header-5</v-icon>
              <v-icon v-if="currentTextFormat==='h6'">mdi-format-header-6</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editor.chain().focus().setParagraph().run()">
              <v-list-item-action>
                <v-icon>mdi-format-paragraph</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ L('Paragraph') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
              <v-list-item-action>
                <v-icon>mdi-format-header-1</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ L('Heading 1') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
              <v-list-item-action>
                <v-icon>mdi-format-header-2</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ L('Heading 2') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
              <v-list-item-action>
                <v-icon>mdi-format-header-3</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ L('Heading 3') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editor.chain().focus().toggleHeading({ level: 4 }).run()">
              <v-list-item-action>
                <v-icon>mdi-format-header-4</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ L('Heading 4') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editor.chain().focus().toggleHeading({ level: 5 }).run()">
              <v-list-item-action>
                <v-icon>mdi-format-header-5</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ L('Heading 5') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editor.chain().focus().toggleHeading({ level: 6 }).run()">
              <v-list-item-action>
                <v-icon>mdi-format-header-6</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ L('Heading 6') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn class="ma-1" small icon @click="editor.chain().focus().toggleBold().run()">
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn class="ma-1" small icon @click="editor.chain().focus().toggleItalic().run()">
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>

        <v-btn class="ma-1" small icon @click="editor.chain().focus().toggleBulletList().run()">
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-btn class="ma-1" small icon @click="editor.chain().focus().toggleOrderedList().run()">
          <v-icon>mdi-format-list-numbered</v-icon>
        </v-btn>
        <v-spacer/>
        <v-btn class="ma-1" small icon @click="editor.chain().focus().undo().run()">
          <v-icon>mdi-undo</v-icon>
        </v-btn>
        <v-btn class="ma-1" small icon @click="editor.chain().focus().redo().run()">
          <v-icon>mdi-redo</v-icon>
        </v-btn>
        <v-btn class="ma-1" small icon @click="toggleShowCode()">
          <v-icon v-if="showCode">mdi-format-textbox</v-icon>
          <v-icon v-else>mdi-xml</v-icon>
        </v-btn>
      </div>
      <textarea ref="textarea" :class="'code pr-2 pl-2 ' + (showCode ? 'd-block' : 'd-none')"
                style="min-height: 50px; width: 100%"
                @input="emitValue()">{{value}}</textarea>
      <editor-content :class="'mr-2 ml-2 ' + (showCode ? 'd-none' : 'd-block')" style="min-height: 50px"
                      :editor="editor"/>

    </div>
    <caption v-if="hint" class="full-width text-left d-block">{{ hint }}</caption>
  </div>

</template>

<script>
import {Editor, EditorContent} from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import {mapGetters} from "vuex";

export default {
  name: "Wysiwyg",
  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      timout: null,
      showCode: false,
    }
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    }
  },
  computed: {
    ...mapGetters(['languageMap']),
    currentTextFormat() {
      if (!this.editor) return 'p';
      if (this.editor.isActive('heading', {level: 1})) return 'h1';
      if (this.editor.isActive('heading', {level: 2})) return 'h2';
      if (this.editor.isActive('heading', {level: 3})) return 'h3';
      if (this.editor.isActive('heading', {level: 4})) return 'h4';
      if (this.editor.isActive('heading', {level: 5})) return 'h5';
      if (this.editor.isActive('heading', {level: 6})) return 'h6';
      return 'p';
    }
  },
  methods: {
    L(key) {
      return key;
    },
    toggleShowCode() {
      this.showCode = !this.showCode;
      if (!this.showCode) {
        this.editor.commands.setContent(this.value);
      }
    },
    emitValue() {
      clearTimeout(this.timout);
      this.timout = setTimeout(() => {
        const val = this.showCode ? this.$refs.textarea.value : this.editor.getHTML();
        console.log('emitValue', val);
        if (val) this.$emit('input', val);
      }, 500)
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
      ],
      content: this.value,
    });
    this.editor.on('update', ({editor}) => {
      this.emitValue();
    });
  },

  beforeDestroy() {
    if (this.editor) this.editor.destroy()
  },
}
</script>

<style scoped>

.art-wysiwyg {
  border-collapse: collapse;
  border-color: rgba(0, 0, 0, 0.38);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  transition-duration: 0.15s;
  transition-property: color;
  min-height: 100px;
}


.art-wysiwyg ul,
ol {
  padding: 0 1rem;
}

.art-wysiwyg h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

.art-wysiwyg p {
  margin-bottom: 0;
}

.ProseMirror:focus {
  outline: none;
}

.art-wysiwyg .ProseMirror {
  min-height: 50px;
}

.art-wysiwyg textarea.code {
  resize: none;
}

.art-wysiwyg textarea.code:focus {
  outline: none !important;
  border: none !important;
}
</style>