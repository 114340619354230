import { render, staticRenderFns } from "./Wysiwyg.vue?vue&type=template&id=77686628&scoped=true&"
import script from "./Wysiwyg.vue?vue&type=script&lang=js&"
export * from "./Wysiwyg.vue?vue&type=script&lang=js&"
import style0 from "./Wysiwyg.vue?vue&type=style&index=0&id=77686628&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77686628",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_e8b81a9ea5d843a4ef036e3c994eb378/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemAction,VListItemTitle,VMenu,VSpacer})
