<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Media</h2>
            <v-spacer/>
            <v-btn color="accent" class="secondary--text" @click="$router.push('/media/new')">New</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-row row wrap>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field filled rounded dense hide-details v-model="titleSearch" label="Search by title"
                                @input="applyFilter"></v-text-field>
                </v-col>
              </v-row>
              <v-row row wrap>

                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination"
                                :server-items-length="count" :loading="tableLoading"
                                no-data-text="There are no items yet" :footer-props="footerProps">
                    <template v-slot:item.title.en="{ item }">
                      <a :href="'./#/media/'+item._id" :title="item.title.en"><b>{{ item.title.en }}</b></a>
                    </template>
                    <template v-slot:item.cover_url="{ item }">
                      <a class="ma-0" :href="'./#/media/'+item._id">
                        <v-img :src="item.cover_url" contain width="160" height="90"
                               class="black"/>
                      </a>
                    </template>
                    <template v-slot:item.url="{ item }">
                      <a :href="item.url" v-if="item.url" target="_blank">{{ item.url }}</a>
                    </template>
                    <template v-slot:item.photos="{ item }">
                      <ul>
                        <li v-for="p in getPhotosOf(item._id)" :key="item._id  + '_' + p._id"><a
                            :href="'./#/photos/'+p._id">{{ p.title.en }}</a></li>
                      </ul>
                    </template>
                    <template v-slot:item.external="{ item }">
                      <span v-if="item.external">Yes</span>
                      <span v-else>No</span>
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import config from "../config";
import utils from "../utils";
import _ from "lodash";
import log from "@/log";

export default {
  data() {
    return {
      refreshKey: 0,
      refreshTimeout: -1,
      titleSearch: null,
      tableLoading: false,
      count: 0,
      footerProps: {
        "items-per-page-text": "no. items per page",
        "items-per-page-options": [20, 50, 100],
        "items-per-page": 20
      },
      items: [],
      pagination: {},
      query: {},
    }
  },
  watch: {
    pagination: {
      handler() {
        sessionStorage.mediaPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['is_admin', 'photos']),
    server() {
      return config.server;
    },
    headers() {
      return [
        {text: 'Cover', sortable: false, align: 'left', value: 'cover_url', width: 160},
        {text: 'Title', sortable: true, align: 'left', value: 'title.en'},
        {text: 'Type', sortable: true, align: 'left', value: 'type'},
        {text: 'External', sortable: true, align: 'left', value: 'external'},
        {text: 'Url', sortable: false, align: 'left', value: 'url'},
        {text: 'Photos', sortable: false, align: 'left', value: 'photos'},
        {text: 'Delete', sortable: false, align: 'center', value: 'remove', width: 50},
      ]
    },
  },
  methods: {
    ...mapActions(['get', 'sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'update', 'refreshCollection']),
    getPhotosOf(mediaId) {
      return this.photos.filter(p => p.media_ids && p.media_ids.includes(mediaId))
    },
    applyFilter() {
      this.query = {};
      this.query.title = this.titleSearch ? this.titleSearch : undefined;
      this.pagination.page = 1
      sessionStorage.mediaQuery = JSON.stringify(this.query);
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    requestDelete(id) {
      this.requestConfirm({
        title: 'Delete request', message: 'Are you sure to permanently delete this item?', callback: (accepted) => {
          if (accepted) {
            this.delete({collection: 'media', id}).then(() => {
              this.refresh();
              this.sendSuccess({message: 'Item deleted!'});
            }).catch((err) => {
              let message = err.message ? err.message : "there was an error and the request could not be completed";
              this.sendError({message});
              log.d("there was an error while removing media", err);
              if (err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }
      });
    },
    refresh() {
      this.tableLoading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.pagination;
      let sort = null;
      if (sortBy && sortBy.length) {
        sort = sortBy[0];
        if (sortDesc && sortDesc[0]) {
          sort = encodeURIComponent('-' + sort);
        } else {
          sort = encodeURIComponent('+' + sort);
        }
      }

      let filter = {
        query: this.query,
        limit: itemsPerPage,
        page,
        sort
      };

      this.load({collection: 'media', filter}).then((reply) => {
        this.tableLoading = false;
        if (reply.status === "success") {
          this.count = reply.count;
          this.items = reply.data ? reply.data : [];
        }
      }).catch((err) => {
        this.tableLoading = false;
        this.sendError({message: "there was an error while loading data"});
        log.d("there was an error while loading media: " + err);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    this.refreshCollection({collection: 'photos'})
    if (sessionStorage.mediaQuery) {
      this.query = JSON.parse(sessionStorage.mediaQuery);
      if (this.query.title) {
        this.titleSearch = this.query.title
      }
    }
    if (sessionStorage.mediaPagination) {
      this.pagination = JSON.parse(sessionStorage.mediaPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
  }
}
</script>
