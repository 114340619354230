<template>
  <v-app id="app" light>
    <v-navigation-drawer dark v-if="loggedIn" v-model="drawer" :width="240" clipped fixed class="secondary" app>
      <v-list dense>
        <div v-for="page in pages" :key="page.link">
          <v-list-group v-if="page.items" :prepend-icon="page.icon" :value="isSubPageActive(page.items)"
                        active-class="accent--text">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="subpage in page.items" :key="subpage.link" :to="subpage.link" router
                         class="menu-subitem pl-6" active-class="accent--text">
              <v-list-item-action>
                <v-icon>{{ subpage.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ subpage.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :to="page.link" router active-class="accent--text">
            <v-list-item-action>
              <v-icon>{{ page.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>


        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Exit</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app fixed clipped-left dark class="primary" v-if="loggedIn">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>
        ArtCentrica NGS&nbsp;<span class="title">ADMIN</span>
      </v-toolbar-title>
      <v-spacer/>
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-slide-x-transition>
        <router-view :key="$route.path"/>
      </v-slide-x-transition>


      <v-dialog v-model="confirmDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{ confirmDialogTitle }}</v-card-title>
          <v-card-text>
            <p class="body-1" v-html="confirmDialogMessage"></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.native="confirmDialogCallback(false); confirmDialog = false">No</v-btn>
            <v-btn color="error" text @click.native="confirmDialogCallback(true);  confirmDialog = false">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
          dark
          :timeout="5000"
          :color="snackbarColor"
          top
          multi-line
          v-model="snackbar">
        {{ dialogMessage }}
      </v-snackbar>

    </v-main>
  </v-app>
</template>
<script>
import {mapActions, mapState, mapGetters} from 'vuex'

export default {
  name: 'app',
  data() {
    return {
      drawer: true,
      loading: false
    }
  },
  computed: {
    ...mapState(['confirmDialogTitle', 'confirmDialogCallback', 'dialogMessage']),
    ...mapGetters(['token', 'profile', 'is_admin', 'is_root', 'confirmDialogMessage']),
    pages() {
      let items = [
        {title: "Photos", icon: "mdi-panorama", link: "/photos"},
        {title: "Photographers", icon: "mdi-camera", link: "/authors"},
        {title: "Images", icon: "mdi-image-multiple", link: "/imgfs"},
        {title: "Media", icon: "mdi-play-network", link: "/media"},
        //{title: "POIs", icon: "mdi-map-marker", link: "/poi"},
        {title: "Continents", icon: "mdi-earth", link: "/continents"},
        {title: "Countries", icon: "mdi-flag", link: "/countries"},
        {title: "Hotspot Categories", icon: "mdi-pound-box-outline", link: "/hspcategories"},
        {title: "Categories", icon: "mdi-tag-multiple", link: "/categories"},
        {title: "Bioregions", icon: "mdi-texture-box", link: "/bioregions"},
        {title: "Sections", icon: "mdi-view-dashboard", link: "/sections"},
        {title: "Lessons", icon: "mdi-school", link: "/lessons"},
        {
          title: "Survey", icon: 'mdi-account-question', items: [
            {type: 'item', title: "Choices", icon: "mdi-message-question", link: "/survey"},
            {type: 'item', title: "Results", icon: "mdi-forum", link: "/survey/results"},
          ]
        },
        {title: "CMS", icon: "mdi-text", link: "/cms"},

      ];
      if (this.is_admin || this.is_root) {
        items.push(
            {title: "Settings", icon: "mdi-cog", link: "/settings"},
            {title: "Users", icon: "mdi-account", link: "/users"},
        );
      }
      return items
    },
    loggedIn() {
      return this.profile != null
    },
    successDialog: {
      get() {
        return this.$store.state.successDialog;
      },
      set(newVal) {
        this.$store.state.successDialog = newVal;
      }
    },
    warningDialog: {
      get() {
        return this.$store.state.warningDialog;
      },
      set(newVal) {
        this.$store.state.warningDialog = newVal;
      }
    },
    errorDialog: {
      get() {
        return this.$store.state.errorDialog;
      },
      set(newVal) {
        this.$store.state.errorDialog = newVal;
      }
    },
    infoDialog: {
      get() {
        return this.$store.state.infoDialog;
      },
      set(newVal) {
        this.$store.state.infoDialog = newVal;
      }
    },
    confirmDialog: {
      get() {
        return this.$store.state.confirmDialog;
      },
      set(newVal) {
        this.$store.state.confirmDialog = newVal;
      }
    },
    snackbar: {
      get() {
        return this.successDialog || this.warningDialog || this.errorDialog || this.infoDialog;
      },
      set(newVal) {
        this.successDialog = newVal;
        this.warningDialog = newVal;
        this.errorDialog = newVal;
        this.infoDialog = newVal;
      }
    },
    snackbarColor() {
      if (this.successDialog) return 'success';
      if (this.errorDialog) return 'error';
      if (this.warningDialog) return 'warning';
      return undefined;
    },
  },
  methods: {
    ...mapActions(['login', 'logout', 'refreshToken', 'setToken', 'setProfile', 'sendError']),
    isSubPageActive(items) {
      let filter = items.filter((item) => {
        return this.$route.path.includes(item.link, 0);
      })
      return filter.length > 0
    },
  },
  async mounted() {
    if (this.token) {
      await this.refreshToken()
    } else {
      this.logout()
    }
  }
}
</script>
