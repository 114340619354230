<template>
    <div id="xlv-wrap" >
        <div id="viewer" :class="'xlviewer ' + className"></div>
        <div v-if="hotspots && hotspots.length" id="hotspots" :key="'hsps_'+seed" :class="tracking ? 'editing' : ''"
             @mousemove="mouseMove" @mouseup="mouseUp" @mouseleave="mouseUp" >
            <div v-for="(hsp, index) in hotspots" :class="['hotspot', (hsp.editing ? 'editing':'')]"
                 :key="hsp._id" :style="buildHotspotStyle(hsp)" v-if="hsp.show" @mousedown="trackMouse($event, hsp, 'drag')">
                <div class="corner accent topLeft" @mousedown="trackMouse($event, hsp, 'topLeft')"></div>
                <div class="corner accent bottomRight" @mousedown="trackMouse($event, hsp, 'bottomRight')"></div>
            </div>
        </div>
    </div>

</template>

<script>
    import utils from '../utils'
    import XLviewer from '../xlviewer'
    export default {
        name: "XlViewer",
        data() {
            return {
                model : this.value,
                viewer : null,
                toolbar : null,
                seed : 0,
                editingHotspot : null,
                tracking : false,
                trackingType : '',
                startingEvent : null,
                loop : null
            }
        },
        props: {
            value : {
                type: String,
                required: true,
            },
            hotspots : {
                type: Array,
                default: () => {
                    return [];
                }
            },
            server : {
                type: String,
                required: true
            },
            quality : {
                type: Number,
                default: 70
            },
            className: {
                type: String,
                default : ''
            },
            forceHttps : {
                type :Boolean,
                default : false
            }
        },
        watch : {
            value() {
                this.model = this.value;
                console.log("change viewer model")
                this.load();
            },
        },
        computed : {
            serverUrl() {
                if(!this.server) return '';
                let url = this.server;
                if(this.forceHttps && url.indexOf('https://') === -1) {
                    url = url.replace('http://', 'https://')
                }
                return url;
            }
        },
        methods: {
            trackMouse(e, hsp, type) {
                e.stopImmediatePropagation();
                this.editingHotspot = hsp;
                this.trackingType = type;
                this.tracking = true;
                this.startingEvent = e;
            },
            mouseMove(e) {

                if(!this.tracking) {
                    return
                }

                const target = document.getElementById('hotspots');
                const position = utils.getMouseCoordinates(e, target);
                let topLeft, bottomRight, coords;
                switch (this.trackingType) {
                    case 'drag':
                        topLeft = {
                            x: position.x-6,
                            y: position.y-6,
                        };
                        coords = this.viewer.sceneToLocal(new XLviewer.Point(topLeft.x,topLeft.y));
                        this.editingHotspot.x = coords.x - this.editingHotspot.w/2;
                        this.editingHotspot.y = coords.y -  this.editingHotspot.h/2;
                        break;
                    case 'topLeft':
                        topLeft = {
                            x: position.x-6,
                            y: position.y-6,
                        };
                        coords = this.viewer.sceneToLocal(new XLviewer.Point(topLeft.x,topLeft.y));
                        bottomRight = {
                            x : this.editingHotspot.x + this.editingHotspot.w,
                            y : this.editingHotspot.y + this.editingHotspot.h,
                        };
                        this.editingHotspot.x = coords.x;
                        this.editingHotspot.y = coords.y;
                        this.editingHotspot.w = bottomRight.x - this.editingHotspot.x;
                        this.editingHotspot.h = bottomRight.y - this.editingHotspot.y;
                        break;
                    case 'bottomRight':
                        bottomRight = {
                            x: position.x+6,
                            y: position.y+6,
                        };
                        coords = this.viewer.sceneToLocal(new XLviewer.Point(bottomRight.x,bottomRight.y));
                        this.editingHotspot.w = coords.x - this.editingHotspot.x;
                        this.editingHotspot.h = coords.y - this.editingHotspot.y;
                        break;
                }
                //this.seed++;
            },
            mouseUp(e) {
                if(!this.tracking) {
                    return
                }
                console.log("up");
                this.editingHotspot = null;
                this.trackingType = null;
                this.tracking = false;
                this.startingEvent = null;
            },
            zoomIn() {
                this.viewer.zoomIn()
            },
            zoomOut() {
                this.viewer.zoomOut()
            },
            reset() {
                this.viewer.zoomReset()
            },
            getBounds( current ) {
                return this.viewer.getBounds( current )
            },
            zoomToBounds(x, y, w, h, immediately, applyConstraint) {
                this.viewer.zoomToBounds( new XLviewer.Rect(x,y,w,h), immediately, applyConstraint);
            },
            _build() {
                if(!this.model)  {
                    return;
                }
                console.log("build viewer")
                this.viewer = new XLviewer({
                    // debug: true,
                    id: 'viewer',
                    quality: this.quality,
                    useNavigator : false,
                    source : this.serverUrl,
                    image : this.model
                });
            },
            buildHotspotStyle(hsp) {
                let topLeft = this.viewer.localToScene(new XLviewer.Point(hsp.x,hsp.y));
                let bottomRight = this.viewer.localToScene(new XLviewer.Point(hsp.x+hsp.w,hsp.y+hsp.h));
                return `top: ${topLeft.y}px; left: ${topLeft.x}px; width: ${bottomRight.x - topLeft.x}px;  height: ${bottomRight.y - topLeft.y}px`;
            },
            load() {
                if(this.viewer) {
                    this.viewer.destroy();
                }
                this._build();
            },
            onInput(val) {
                this.$emit('input', this.model)
            },
            setHotspots( items ) {
                this.hotspots = items;
                this.seed++
            },
            forceRedraw() {
                this.seed++
            }
        },
        mounted() {
            if(!this.value) return;
            this.load();
        },
        beforeDestroy() {
            this.viewer && this.viewer.destroy();
        }
    }
</script>

<style scoped>
    #xlv-wrap{
        position: relative;
        background: black;
        width: 100%;
        height: 500px;
    }

    #viewer, #hotspots {
        position: absolute;
        overflow: hidden;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    #hotspots {
        pointer-events:none;
    }

    #hotspots .hotspot {
        cursor: auto;
        position: absolute;
        pointer-events:none;
        background: rgba(255,255,255,.3);
        border: 2px solid rgba(255,255,255,.5);
        z-index: 9999;
    }
    #hotspots.editing{
        pointer-events:all;
    }

    #hotspots .hotspot.editing {
        pointer-events:all;
        cursor: pointer;
    }

    #hotspots .hotspot .corner {

        display: none;
        position: absolute;
        width: 12px;
        height: 12px;
    }
    #hotspots .hotspot.editing .corner{
        display: block;
        pointer-events:all;
        cursor: pointer;
    }

    #hotspots .hotspot .corner.topLeft {
        top: 0;
        left: 0;
    }
    #hotspots .hotspot .corner.bottomRight {
        bottom: 0;
        right: 0;
    }


</style>

