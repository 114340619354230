<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">CMS</h2>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="keySearch" label="Search by key"
                                @input="applyFilter"
                                append-icon="mdi-magnify" clearable></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-divider/>
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="12">
                  <v-data-iterator :items="items" :options.sync="pagination" :server-items-length="count"
                                   :loading="tableLoading"
                                   no-data-text="There are no records yet" :footer-props="footerProps">
                    <template v-slot:default="props">
                      <v-row v-for="item in props.items" :key="item._id">
                        <v-col cols="12">Key:
                          <h3>{{ item.key }}
                            <v-btn color="error" icon @click="requestDelete(item._id)">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </h3>
                        </v-col>
                        <v-col cols="12">
                          <v-switch outlined dense label="Rich text / Plain Text" v-model="item.plain_text"
                                    hide-details></v-switch>
                        </v-col>
                        <v-col cols="12" v-if="item.plain_text">
                          <v-textarea label="Text" v-model="item.text.en" outlined dense></v-textarea>
                        </v-col>
                        <v-col cols="12" v-else>
                          <wysiwyg label="Text" v-model="item.text.en"></wysiwyg>
                        </v-col>
                        <v-col cols="12" md="6" lg="4" xl="3">
                          <v-btn @click="updateLocalization(item)" color="accent">Save</v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-divider/>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </v-col>

                <v-col cols="12">
                  <v-divider/>
                </v-col>

                <v-col cols="12">

                  <v-row>
                    <v-col cols="12"><h3>Insert a new text</h3></v-col>
                    <v-col cols="12">
                      <v-switch outlined dense label="Rich text / Plain Text" v-model="newItem.plain_text"
                                hide-details></v-switch>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field outlined dense label="CMS Key" v-model="newItem.key" :error="keyError"
                                    :error-messages="keyErrorMessages"></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="newItem.plain_text">
                      <v-textarea label="Text" v-model="newItem.text.en" outlined dense></v-textarea>
                    </v-col>
                    <v-col cols="12" v-else>
                      <wysiwyg label="Text" v-model="newItem.text.en"></wysiwyg>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" xl="3">
                      <v-btn @click="insertLocalization()" color="accent">Insert</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import config from "../config";
import {mapActions, mapGetters} from "vuex";
import _ from 'lodash'
import Wysiwyg from "@/components/Wysiwyg";
import log from "@/log";

export default {
  name: "CMS",
  components: {
    Wysiwyg
  },
  data() {
    return {
      refreshTimeout: -1,
      keyError: false,
      keySearch: null,
      tableLoading: false,
      plainTextMode: true,
      count: 0,
      items: [],
      footerProps: {
        "items-per-page-text": 'No. rows per page',
        "items-per-page-options": [10, 20, 50],
        "items-per-page": 10
      },
      pagination: {},
      query: {},
      newItem: {
        key: "",
        plain_text: false,
        text: {en: ''},
      }
    };
  },
  watch: {
    pagination: {
      handler() {
        sessionStorage.cmsPagination = JSON.stringify(this.pagination)
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["token"]),
    keyErrorMessages() {
      return this.keyError ? ["required field"] : [];
    }
  },
  methods: {
    ...mapActions(['get', 'load', 'sendError', 'sendSuccess', 'requestConfirm', 'upload', "update", "insert", "delete", 'logout']),
    applyFilter() {
      this.query = {};
      this.query.key = this.keySearch ? this.keySearch : undefined;
      this.pagination.page = 1
      sessionStorage.cmsQuery = JSON.stringify(this.query)
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    insertLocalization() {
      this.keyError = false;
      if (!this.newItem.key) {
        this.keyError = true;
        return;
      }
      this.insert({collection: 'texts', data: this.newItem}).then((reply) => {
        this.sendSuccess({message: "Text saved!"});
        this.refresh()
        this.newItem = {
          key: "",
          text: {en: ''},
        };
      }).catch((err) => {
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    updateLocalization(data) {
      this.update({collection: 'texts', data}).then((reply) => {
        this.sendSuccess({message: "Text saved!"});
        this.refresh()
      }).catch((err) => {
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    requestDelete(id) {
      this.requestConfirm({
        title: 'Delete request', message: 'Are you sure to delete this item permanently?', callback: (accepted) => {
          if (accepted) {
            this.delete({collection: 'texts', id}).then(() => {
              this.refresh();
              this.sendSuccess({message: 'Item deleted!'});
            }).catch((err) => {
              let message = err.message ? err.message : 'there was an error while removing the item';
              this.sendError({message});
              if (err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }
      });
    },
    refresh() {
      this.tableLoading = true;
      const {page, itemsPerPage} = this.pagination;
      let filter = {
        query: this.query,
        limit: itemsPerPage,
        page,
        sort: null,
        order: null
      };

      this.load({collection: 'texts', filter}).then((reply) => {
        this.tableLoading = false;
        if (reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err) => {
        this.tableLoading = false;
        this.sendError({message: "there was an error while loading data"});
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  async mounted() {
    if (sessionStorage.cmsQuery) {
      this.query = JSON.parse(sessionStorage.cmsQuery)
      this.keySearch = this.query.key ? this.query.key : ''
    }
    if (sessionStorage.cmsPagination) {
      this.pagination = JSON.parse(sessionStorage.cmsPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
  }
}
</script>

<style scoped>

</style>
