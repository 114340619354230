<template>
  <v-container fluid grid-list-md>
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{ title }}</h2>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-row row wrap>
                <v-col cols="12" lg="8">
                  <v-container grid-list-md>
                    <v-row row wrap>
                      <v-col cols="12">
                        <xl-viewer ref="xlviewer" :server="xlserver" :hotspots="hotspots" v-model="imgf" force-https
                                   v-if="xlserver && imgf"/>
                      </v-col>
                      <v-col cols="12" class="text-xs-center">
                        <v-btn icon text color="accent" @click="zoomIn()">
                          <v-icon>mdi-magnify-plus</v-icon>
                        </v-btn>
                        <v-btn icon text color="accent" @click="zoomOut()">
                          <v-icon>mdi-magnify-minus</v-icon>
                        </v-btn>
                        <v-btn icon text color="accent" @click="reset()">
                          <v-icon>mdi-autorenew</v-icon>
                        </v-btn>
                        <v-btn icon text color="accent" @click="newHotspot()">
                          <v-icon>mdi-shape-square-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-col>
                <v-col cols="12" lg="4">
                  <h2 class="title mb-2">{{ L('Hotspots list') }}</h2>
                  <v-checkbox v-model="showingAll" :indeterminate="showingSomething && !showingAll"
                              :label="L('Show all')"></v-checkbox>
                  <v-list subheader two-line>
                    <template v-for="(item, index) in hotspots">
                      <v-divider :key="index"/>
                      <v-list-item :key="item._id">
                        <v-list-item-action>
                          <v-checkbox v-model="item.show" @change="refreshView"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content @click="item.show = !item.show">
                          <v-list-item-title><b>{{ item.ctg ? item.ctg.name.en : '**no category**' }}</b>
                          </v-list-item-title>
                          <v-list-item-subtitle class="accent--text">{{ parentsOf(item.ctg) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>x:{{ item.x.toFixed(4) }}, y:{{ item.y.toFixed(4) }},
                            w:{{ item.w.toFixed(4) }}, h:{{ item.h.toFixed(4) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn v-if="!item.editing" icon text color="accent" @click="edit(item)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn v-if="item.editing" icon text color="success" @click="save(item)">
                            <v-icon>mdi-content-save</v-icon>
                          </v-btn>
                          <v-btn v-if="!item.editing" icon text color="error" @click="remove(item._id)">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>

                  </v-list>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <!--v-card-actions>
              <v-spacer/>
              <v-btn text color="accent" @click="save()">Salva</v-btn>
          </v-card-actions-->
        </v-card>
      </v-col>
    </v-row>


    <v-dialog v-model="newHspDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">{{ L('Insert hotspot') }}</v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-row row>
              <v-col cols="12">
                <v-autocomplete outlined dense v-model="newHsp.ctg_id" label="Hotspot Category"
                                :items="categoryOptions">
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"></v-list-item-title>
                      <v-list-item-subtitle class="accent--text" v-html="data.item.parent"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="newHspDialog = false">{{ L('Cancel') }}</v-btn>
          <v-btn color="accent" text @click="create(); newHspDialog = false" :disabled="!newHsp.ctg_id">{{
              L('Insert')
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import utils from '../utils';
import {mapGetters, mapActions} from 'vuex';
import config from "../config";
import _ from 'lodash'
import XlViewer from "../components/XLviewer";

export default {
  components: {XlViewer},
  data() {
    return {
      loading: false,
      xlserver: null,
      imgf: null,
      newHspDialog: false,
      newHsp: {
        ctg_id: null,
        photo_id: null,
      },
      photo: null,
      hotspots: [],
      categoriesMap: {}
    }
  },
  computed: {
    ...mapGetters(['profile', 'hspcategories']),
    title() {
      return this.photo ? this.L('Hotspots of') + ' "' + this.photo.title.en + '"' : this.L('Hotspots');
    },
    showingAll: {
      get() {
        for (let i = 0; i < this.hotspots.length; i++) {
          if (!this.hotspots[i].show) return false;
        }
        return true;
      },
      set(val) {
        for (let i = 0; i < this.hotspots.length; i++) {
          this.hotspots[i].show = val;
        }
        this.refreshView();
      }
    },
    showingSomething() {
      for (let i = 0; i < this.hotspots.length; i++) {
        if (this.hotspots[i].show) return true;
      }
      return false;
    },

    categoryOptions() {
      let filter = this.hspcategories.filter((item) => {
        let children = this.hspcategories.filter((test) => test.parent_id === item._id)
        return !children || !children.length;
      });
      return filter.map((item) => {
        let parent = this.parentsOf(item);
        let text = item.name.en || item._id;
        return {text, parent, value: item._id}
      }).sort(utils.sortTextOn('text'))
    },

  },
  methods: {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'delete', 'get', 'update', 'insert', 'refreshCollection', 'refreshCollectionAsync']),
    L(key) {
      return key;
    },
    parentsOf(item) {
      if (!item) return ''
      let parentObj = item.parent_id ? this.categoriesMap[item.parent_id] : null
      let parent = parentObj ? parentObj.name[this.language] || parentObj.name.it || parentObj.name.en || parentObj._id : '';

      if (parentObj) {
        while (parentObj !== null) {
          parentObj = parentObj.parent_id ? this.categoriesMap[parentObj.parent_id] : null
          if (parentObj) {
            parent = (parentObj.name[this.language] || parentObj.name.it || parentObj.name.en || parentObj._id) + " > " + parent;
          }
        }
      }
      return parent;
    },
    zoomIn() {
      this.$refs.xlviewer.zoomIn()
    },
    zoomOut() {
      this.$refs.xlviewer.zoomOut()
    },
    reset() {
      this.$refs.xlviewer.reset()
    },
    newHotspot() {
      this.newHspDialog = true;
    },
    applyFilter() {
      this.loadHotspots()
    },
    loadHotspots() {
      this.get({collection: 'photos', id: `${this.photo._id}/hotspots`}).then((reply) => {
        if (reply.data) {
          this.hotspots = reply.data;
        }
      }).catch((err) => {
        if (config.debug) console.log(err);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    loadPhoto() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection: 'photos', id}).then((reply) => {
        this.loading = false;
        if (reply.data) {
          this.photo = reply.data;
          let {path, name} = utils.splitUrl(this.photo.imgf_url);
          this.xlserver = path;
          this.imgf = name;
          this.loadHotspots();
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.go(-1);
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    create() {
      this.newHsp.x = 0.4;
      this.newHsp.y = 0.4;
      this.newHsp.w = 0.2;
      this.newHsp.h = 0.2;
      this.newHsp.photo_id = this.photo._id;

      this.insert({collection: 'hotspots', data: this.newHsp}).then((reply) => {
        if (reply.status === 'success' && reply.data) {
          this.newHsp = {
            ctg_id: null,
          };
          reply.data.editing = true;
          reply.data.show = true;
          reply.data.photo = this.photo;
          reply.data.ctg = this.categoriesMap[reply.data.ctg_id];
          this.hotspots.push(reply.data);
          this.$forceUpdate();
          this.$refs.xlviewer.forceRedraw();
        }
      }).catch((err) => {
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          this.logout();
        }
      })
    },
    edit(item) {
      item.editing = true;
      item.show = true;
      this.$forceUpdate();
      this.$refs.xlviewer.forceRedraw();
    },
    save(item) {
      item.editing = false;
      this.$forceUpdate();
      this.$refs.xlviewer.forceRedraw();
      this.update({collection: 'hotspots', data: item}).then((reply) => {
        if (config.debug) console.log(reply);
        this.sendSuccess({message: 'Hotspot salvato!'})
      }).catch((err) => {
        if (config.debug) console.log(err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if (err.statusCode === 401) {
          this.logout();
        }
      });
    },
    remove(id) {
      this.requestConfirm({
        title: 'Delete request', message: 'Are you sure to delete this item permanently?', callback: (accepted) => {
          if (accepted) {
            this.delete({collection: 'hotspots', id}).then(() => {
              this.refreshCollection({collection: 'hspcategories'});
              this.sendSuccess({message: 'Item deleted!'});
              this.loadHotspots();
            }).catch((err) => {
              let message = err.message ? err.message : "there was an error while removing data";
              this.sendError({message});
              if (config.debug) console.error("Errore di cancellazione dell'hotspot", err);
              if (err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }
      });
    },
    refreshView() {
      setTimeout(() => {
        this.$forceUpdate();
        this.$refs.xlviewer.forceRedraw();
      }, 250);
    },
  },
  async mounted() {
    await this.refreshCollectionAsync({collection: 'hspcategories'});
    this.categoriesMap = _.keyBy(this.hspcategories, '_id');
    this.loadPhoto();
  }
}
</script>
